export const routes = {
	login: '/login',
	resetPassword: '/reset-password',
	forgotPassword: '/forgot-password',
	register: '/register',
	accountVerification: '/register/account-verification',
	updatePassword: '/update-password',
	users: '/:id',
	thankyou: '/thankyou',

	dashboard: '/app/dashboard',
	changePassword: '/app/change-password',
	schedule: '/app/settings/schedules',
	profile: '/app/profile',
	booking: '/app/bookings',
	emailSetting: '/app/settings/email',
	createSchedule: '/app/settings/create-schedules',
	branche: '/app/branches',
	provider: '/app/providers',
	service: '/app/services'
}
