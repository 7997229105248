import React from 'react';
// import { ThankYouProfile } from 'assets/images';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApplyLogo, GoogleLogo, HomeIcon, OutlookLogo, SuccessIcon, YahooLogo } from 'assets/icons';
import { capitalizeFirstLetter, doDateFormat, formatDateGoogle, formatDateYahoo, formatTime } from 'utils/common-functions';
import moment from 'moment';
import { SEOHeader } from 'views/components';
import { Button } from 'views/stories';
// import { Button } from 'views/stories';s

const Thankyou = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	// Prepare event details
	const eventName = `Appointment with ${capitalizeFirstLetter(state?.service_label || '') || '-'}`;
	const startDateGoogle = formatDateGoogle(state?.booking_date || new Date(), state?.start_time || '');
	const endDateGoogle = formatDateGoogle(state?.booking_date || new Date(), state?.end_time || '');
	const startDateYahoo = formatDateYahoo(state?.booking_date || new Date(), state?.start_time || '');
	const endDateYahoo = formatDateYahoo(state?.booking_date || new Date(), state?.end_time || '');
	const description = `Booking ID: ${state?.booking_number}\nServicer: ${capitalizeFirstLetter(state?.service_label || '') || '-'}\nCustomer: ${capitalizeFirstLetter(state?.customer_name) || ''}\nEmail: ${state?.customer_email}\nPhone: ${state?.customer_phone1}\n\nDetails: Appointment on ${doDateFormat(state?.booking_date || new Date(), 'MMMM D, YYYY')} from ${formatTime(state?.start_time)} to ${formatTime(state?.end_time)}`;
	// const location = ''; // Change this to actual location if available

	// Function to create an iCalendar file
	const createICalFile = () => {
		const icsData = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//YourCompany//YourProduct//EN
BEGIN:VEVENT
UID:${state.booking_number}@yourdomain.com
DTSTAMP:${moment().utc().format('YYYYMMDDTHHmmss[Z]')}
SUMMARY:${eventName}
DTSTART:${startDateGoogle}
DTEND:${endDateGoogle}
DESCRIPTION:${`Booking ID: ${state?.booking_number}, Servicer: ${capitalizeFirstLetter(state?.service_label || '') || '-'}, Customer: ${capitalizeFirstLetter(state?.customer_name) || ''}, Email: ${state?.customer_email}, Phone: ${state?.customer_phone1}, Appointment on ${doDateFormat(state?.booking_date || new Date(), 'MMMM D, YYYY')} from ${formatTime(state?.start_time)} to ${formatTime(state?.end_time)}`}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;

		const blob = new Blob([icsData], { type: 'text/calendar' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'event.ics';
		a.click();
		URL.revokeObjectURL(url);
	};

	return (
		<>
			<SEOHeader title="Thankyou" />

			<div className="thankyou-card">
				<div className="col-9">
					<div className="card">
						<div className="text-end">
							{/* <button type="button" className="btn btn-success px-3 py-2" onClick={() => navigate(-1)}>	<img src={HomeIcon} alt="Google Logo" className="img-fluid" height={20} width={22} /></button> */}
							<Button
								label="Go back"
								className="btn btn-success px-3 py-2"
								onClick={() => navigate(-1)}
							>

								<img src={HomeIcon} alt="Google Logo" className="img-fluid" height={22} width={22} />
							</Button>

						</div>
						<div className="card-header text-center">
							<div className="brand-logo">
								<a href="#">
									<img src={SuccessIcon} alt="Success Icon" className="img-fluid" height={70} width={70} />

								</a>
								<p>Booking ID:<span>&nbsp;#{state?.booking_number || ''}</span></p>
								<div className="your-appointment">
									<h4>Your Appointment Booked successfully!</h4>
								</div>
								<div className="information-email">
									<p>We have sent your booking information to your email address.</p>
								</div>
							</div>

						</div>
						<div className="card-body text-center">
							<div className="row">
								<div className="col-4">
									<div className="service-card">
										<p>Service:</p>
										<h6>{capitalizeFirstLetter(state?.service_label) || '-'}</h6>
									</div>
								</div>

								<div className="col-4 border-start border-end">
									<div className="service-card">
										<p>Date & Time</p>
										<h6>{doDateFormat(state?.booking_date || new Date(), 'MMMM D, YYYY')}</h6>
										<h6>{`${formatTime(state?.start_time)} - ${formatTime(state?.end_time)}` || '-'}</h6>
									</div>
								</div>

								<div className="col-4">
									<div className="service-card">
										<p>Customer:</p>
										<h6>{capitalizeFirstLetter(state?.customer_name) || ''}</h6>
										<h6>{state?.customer_phone1 || ''}</h6>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer border-top pb-0">
							<div className="calendar-logo-list">
								<p>Add to calendar</p>
								<ul className="list-inline">
									<li className="list-inline-item">
										<a href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventName)}&dates=${startDateGoogle}/${endDateGoogle}&details=${encodeURIComponent(description)}`} className="btn btn-outline-success" target="_blank" rel="noopener noreferrer">
											<img src={GoogleLogo} alt="Google Logo" className="img-fluid" height={22} width={22} />&nbsp;&nbsp;Google Calendar
										</a>
									</li>
									<li className="list-inline-item">
										<a href={`https://calendar.yahoo.com/?v=60&title=${encodeURIComponent(eventName)}&st=${startDateYahoo}&et=${endDateYahoo}&desc=${encodeURIComponent(description)}`} className="btn btn-outline-success" target="_blank" rel="noopener noreferrer">
											<img src={YahooLogo} alt="Yahoo Logo" className="img-fluid" height={22} width={22} />&nbsp;&nbsp;Yahoo Calendar
										</a>
									</li>
									<li className="list-inline-item">
										<a href={`https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(eventName)}&startdt=${startDateGoogle}&enddt=${endDateGoogle}&body=${encodeURIComponent(description)}`} className="btn btn-outline-success" target="_blank" rel="noopener noreferrer">
											<img src={OutlookLogo} alt="Outlook Logo" className="img-fluid" height={22} width={22} />&nbsp;&nbsp;Outlook Calendar
										</a>
									</li>

									<li className="list-inline-item">
										<button className="btn btn-outline-success" onClick={createICalFile}>
											<img src={ApplyLogo} alt="Apply Logo" className="img-fluid" height={22} width={22} />&nbsp;&nbsp;iCal Calendar
										</button>
										{/* <a href="#" className="btn btn-outline-success" onClick={createICalFile}>
											<img src={ApplyLogo} alt="Apply Logo" className="img-fluid" height={25} width={25} />&nbsp;&nbsp;iCal Calendar
										</a> */}
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Thankyou