import React, { useState } from 'react'
import { syncTimeService } from 'services'
import { _Object, closeModal } from 'utils/common-functions'

const DeleteService = ({ getServices, selectedService }: _Object) => {
	const [loading, setLoading] = useState<boolean>(false)

	const deleteItem = () => {
		setLoading(true)

		syncTimeService.deleteService(selectedService.id).then((data: _Object) => {
			!data.error && getServices()
			setLoading(false)
			closeModal('DeleteService')
		})
	}

	return (
		<div className="modal fade" id="DeleteService" aria-labelledby="DeleteServiceLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
			<div className="modal-dialog modal-dialog-centered ">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="mb-0">Confirm Delete</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						Are you sure?
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary mx-3" data-bs-dismiss="modal" aria-label="Close">No, cancel</button>
						<button type="submit" className={`btn btn-danger ${loading ? 'btn-loading' : ''} `}
							disabled={loading} onClick={deleteItem}>Yes, delete</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default DeleteService