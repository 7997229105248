import React from 'react'

export interface buttonProps {
	label?: string,
	type?: 'button' | 'submit'
	name?: string,
	className?: string
	variants?: 'danger' | 'primary'
	loading?: boolean,
	children?: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean
}

const Button = (props: buttonProps) => {
	return (
		<>
			<button
				name={props.name}
				type={props.type}
				className={`btn btn-${props.className} ${props.loading ? 'btn-loading' : ''} `}
				disabled={props.disabled || props.loading}
				onClick={props?.onClick}
				{...props}>
				{props.children || props.label}
			</button>
		</>
	)
}
export default Button