import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppDispatch } from 'redux/store'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, CheckBox, InputField, PasswordField } from 'views/stories';

import { authService } from 'services'
import { setLoggedInUser, setTokenSession } from 'redux/slices/session.slice'
import { Logo } from 'assets/images'
import { HandMobile } from '../images'
import { SEOHeader } from 'views/components'
import { routes } from 'utils/common-functions'
import '../style.scss'
import Alert from 'views/components/alerts'

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch<AppDispatch>()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			email: Yup.string().label('').required('Email is required').email(),
			password: Yup.string()
				.label('Password')
				.required()
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
					'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character'
				)
		}),

		onSubmit: async (values) => {
			setError({});
			setLoading(true);
			try {
				const data = await authService.login(values);
				if (data.error === false) {
					dispatch(setTokenSession(data.access_token));
					dispatch(setLoggedInUser());
					navigate(`${routes.dashboard}`);
				} else {
					setError(data);
				}
			} catch (err: any) {
				setError({ error: true, message: err.message || 'An unexpected error occurred' });
			} finally {
				setLoading(false);
			}
		}
	})

	return (
		<>
			<SEOHeader title="Login" />
			<div className="col-lg-6 col-md-12">
				<div className="login-form-col">
					<div className="brand-logo">
						<img src={Logo} alt="Logo" className="brand-logo-images" />
						<h4>Login</h4>
						<p>Enter your credentials to access your account.</p>
					</div>

					<form onSubmit={formik.handleSubmit}>
						<InputField
							type="email"
							name="email"
							label="Email"
							placeholder="Enter here"
							required={true}
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
						/>

						<div className="login-password-field">
							<PasswordField
								name="password"
								label="Password"
								required={true}
								placeholder="Enter here"
								value={formik.values.password}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
							/>
						</div>

						<div className="forgot-password-link">
							<CheckBox
								options={[
									{ label: 'Remember me for 30 days', value: 'Remember me for 30 days' }
								]}
							/>
							<Link className="btn btn-link" to="/forgot-password">Forgot Password?</Link>
						</div>

						<Button name="Sign In" type="submit" className={`btn btn-success ${loading ? 'btn-loading' : ''} `} label="Log In"
							disabled={loading} />
					</form>

					<div className="login-link-button">
						<p>Don&apos;t have an account?&nbsp;<Link className="btn btn-link" to={routes.register}>Register now</Link></p>
					</div>
				</div>
				{error.message && <Alert {...error} />}
			</div>

			<div className="col-lg-6 col-md-12">
				<div className="hand-moblie-col">
					<img src={HandMobile} alt="Hand Mobile" />
				</div>
			</div>
		</>
	)
}

export default Login;