import React, { ChangeEvent } from 'react';

export interface CheckBoxProps {
	label?: string;
	name?: string;
	values?: any;
	onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
	options?: { value: string; label: string; checked?: boolean; onChange?: () => void }[];
	layout?: 'vertical' | 'horizontal';
	className?: string;
	error?: string;
	checked?: boolean;
	switchForm?: string;
}

const CheckBox = (props: CheckBoxProps) => {
	return (
		<div className={`${props.switchForm ? 'form-switch' : 'form-group'} ${props.className}`}>
			{props.label && <label className="form-label">{props.label}</label>}

			{props.options?.map((option, i) => (
				<div className={`${props.switchForm ? 'form-check form-switch' : 'form-check form-check-inline'} ${props.layout === 'horizontal' && 'form-check-inline'}`} key={i}>
					<input
						className="form-check-input" type="checkbox" role="switch"
						name={props.name}
						value={option.value}
						onChange={props.onChange}
						checked={props.values?.includes(option.value)}
						id={`${props.name || ''}-${i}`}
					/>
					<label htmlFor={`${props.name || ''}-${i}`} className="form-check-label">
						{option.label}
					</label>
				</div>
			))}

			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default CheckBox;