import CommonService from './common.service';

class NotificationService extends CommonService {
	async getNotifications() {
		return await this.get('notifications')
	}
	async updateNotification(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`notifications/${id}`, params)
	}
}
export const notificationService = new NotificationService();
