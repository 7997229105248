import React, { FocusEvent } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface PhoneNumberFieldProps {
	label?: string;
	country?: string;
	value: string;
	onChange: (phone: string, country: any) => void;
	onBlur?: (e: FocusEvent<any, Element>) => void; // Update onBlur prop
	required?: boolean;
	disabled?: boolean;
	className?: string;
	placeholder?: string;
	error?: string;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props: PhoneNumberFieldProps) => {
	return (
		<>
			<div className={`form-group mb-3 phone-numer-field ${props.className} ${props.error && 'invalid'}`}>
				{props.label && (
					<label className="form-label">
						{props.label}{props.required && <span className="text-danger">*</span>}
					</label>
				)}

				<PhoneInput
					country={props.country}
					value={props.value}
					onChange={(phone, country) => props.onChange(phone, country)}
					onBlur={props.onBlur}
					disabled={props.disabled}
					placeholder={props.placeholder || 'Enter Helpdesk number'}
				/>
				{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
			</div>
		</>
	);
};

export default PhoneNumberField;
