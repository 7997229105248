import React, { useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { InputField, SelectField, TextArea } from 'views/stories'

import { _Object, closeModal } from 'utils/common-functions'
import { providerService } from 'services'

const AddEditProvider = ({ getProviders, selectedProvider }: _Object) => {
	const [loading, setLoading] = useState<boolean>(false);

	const formik: any = useFormik({
		initialValues: selectedProvider.name ? selectedProvider : {
			name: '',
			status: '',
			description: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			name: Yup.string().label('Name').required(),
			status: Yup.string().label('Status').required()
			// description: Yup.string().label('Description').required()
		}),

		onSubmit: async (values) => {
			setLoading(true)
			if (selectedProvider.name) {
				providerService.updateProvider(selectedProvider.id, {
					description: values.description,
					status: values.status,
					name: values.name
				}).then((data: _Object) => {
					if (!data.error) {
						getProviders()
						formik.resetForm()
						closeModal('NewProvider')
					}
					setLoading(false);
				})
			} else {
				providerService.createProvider(values).then((data: _Object) => {
					if (!data.error) {
						getProviders()
						formik.resetForm()
						closeModal('NewProvider')
					}
					setLoading(false);
				})
			}
		}
	})

	const handleModalBlur = (event: React.FocusEvent<HTMLDivElement>) => {
		// Check if the blur event happened outside the modal
		if (!event.currentTarget.contains(event.relatedTarget as Node)) {
			formik.resetForm()
		}
	};

	return (
		<div className="modal fade" id="NewProvider" aria-labelledby="NewProviderLabel" aria-hidden="true" onBlur={handleModalBlur} tabIndex={-1}>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="mb-0">New Provider</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form onSubmit={formik.handleSubmit}>
						<div className="modal-body">
							<div className="row">
								<div className="col-6">
									<InputField
										type="text"
										name="name"
										label="Name"
										required={true}
										placeholder="Enter here"
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik?.touched.name && formik.errors.name || ''}
									/>
								</div>
								<div className="col-6">
									<SelectField
										name="status"
										label="Status"
										required={true}
										value={{ value: formik.values.status }}
										options={[
											{ label: 'Active', value: 'active' },
											{ label: 'In Active', value: 'inactive' },
											{ label: 'Draft', value: 'draft' }
										]}
										onChange={(e: _Object) => {
											formik.setFieldValue('status', e?.value || '')
										}}
										onBlur={formik.handleBlur}
										error={formik.touched.status && formik.errors.status || ''}
										isClearable
									/>

								</div>
								<div className="col-12">
									<TextArea
										label="Description"
										name="description"
										value={formik.values.description}
										rows={4}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										disabled={false}
										placeholder="Enter here"
										// required={true}
										error={formik?.touched.description && formik.errors.description || ''}
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="submit" className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
								disabled={loading}
							>
								{selectedProvider.id ? 'Update' : 'Submit'}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
export default AddEditProvider;