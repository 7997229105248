import { generateQueryParams } from 'utils/common-functions';
import CommonService from './common.service';

class BookingService extends CommonService {
	async getBookings(params?: { [key: string]: boolean | string }) {
		return await this.get(`bookings/?${generateQueryParams(params)}`)
	}

	async getBookingDetails(id: string | undefined) {
		return await this.get(`bookings/${id}`)
	}

	async createBooking(params: { [key: string]: boolean | string }) {
		return await this.post('bookings', params)
	}

	async updateBooking(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`bookings/${id}`, params)
	}

	async deleteBooking(id: string) {
		return await this.delete(`bookings/${id}`)
	}

	async getBookingSlots(params: { [key: string]: boolean | string }) {
		return await this.get(`bookings/slots?${generateQueryParams(params)}`)
	}
}
export const bookingService = new BookingService();
