import React from 'react';
import { calandarDateFormat, formatTime } from 'utils/common-functions';

const BookingDetails = ({ props, id }: any) => {
	return (
		<div className="modal fade" id={`${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="mb-0">Booking Details</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<p className="text-capitalize"><strong>Booking #:</strong>&nbsp;{props?.booking_number}</p>
						<p className="text-capitalize"><strong>Name:</strong> {props?.customer_name}</p>
						<p><strong>Email:</strong> {props?.customer_email}</p>
						<p><strong>Phone:</strong> {props?.customer_phone1}</p>
						<p><strong>Booking Date:</strong> {calandarDateFormat(props?.booking_date)}</p>
						<p><strong>Time Slot:</strong>  {props?.start_time && props?.end_time ? `${formatTime(props.start_time)} - ${formatTime(props.end_time)}` : '-'}</p>
						<p className="text-capitalize"><strong>Service:</strong> {props?.service?.title}</p>
						<p className="text-capitalize"><strong>Status:</strong> {props?.status}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingDetails;