import React, { useEffect, useState } from 'react'

import { Pagination, SearchField } from 'views/stories'

import { providerService } from 'services/providers.service'
import { SEOHeader } from 'views/components'
import AddEditProvider from './add-edit-provider'
import { _Object } from 'utils/common-functions'
import { EditButton, Plus } from '../assets/icons'
import DeleteProvider from './delete-provider'
import { useFormik } from 'formik'

const Providers = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [providers, setProviders] = useState<_Object>({});
	const [selectedProvider, setSelectedProvider] = useState<_Object>({});
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		size: 10,
		sort: '-created_at',
		q: ''
	});

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '') {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});

	const getProviders = () => {
		setLoading(true);
		providerService.getProviders(filterData).then((data: _Object) => {
			data.items && setProviders(data)
			setLoading(false);
		})
	}
	useEffect(() => { getProviders() }, [filterData])

	return (
		<>
			<div className="providers-section">
				<SEOHeader title="Providers" />

				<div className="space-between align-middles pb-10">
					<h5 className="mb-0">Providers</h5>
					<button type="button" onClick={() => setSelectedProvider({})} className="btn btn-success" data-bs-toggle="modal" data-bs-target="#NewProvider">
						<img src={Plus} alt="Add" width={14} height={14} /> &nbsp;Add Provider
					</button>
				</div>

				<div className="card">
					<div className="card-header space-between align-middles">
						<div className="d-flex gap-3">
							<form onSubmit={formik.handleSubmit}>
								<div className="input-group">
									<SearchField
										name="q"
										value={formik.values.q}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue('q', e.target.value);
										}}
									/>
								</div>
							</form>
							{/* <Filters /> */}
						</div>

						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<a href="#">
								</a>
							</li>

							<li className="list-inline-item">
								<a href="#">
								</a>
							</li>
						</ul>
					</div>

					<div className="card-body table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Status</th>
									<th>Description</th>
									<th className="action-button">Action</th>
								</tr>
							</thead>

							<tbody>
								{loading ?
									<tr>
										<td colSpan={12} className="text-center">
											<div className="d-flex justify-content-center">
												<div className="spinner-border text-secondary" role="status">
												</div>
											</div>
										</td>
									</tr>
									: providers.items?.map((item: any, i: number) => (
										<tr key={i}>
											<td>{i + 1}</td>
											{/* <td>{item.booking_number || ''}</td> */}
											<td>{item.name || ''}</td>
											<td>{item.status || ''}</td>
											<td>{item.description || ''}</td>
											<td>
												<ul className="list-inline">
													{/* <li className="list-inline-item">
														<a href="#" className="eye-button">
															<img src={EyeButton} alt="Eye" width={22} height={20} />
														</a>
													</li> */}
													<li className="list-inline-item">
														<button type="button" onClick={() => setSelectedProvider(item)} data-bs-toggle="modal" data-bs-target="#NewProvider" className="btn p-0 eye-edit">
															<img src={EditButton} alt="Edit" width={22} height={20} />
														</button>

													</li>
													{/* <li className="list-inline-item">
														<button type="button" onClick={() => setSelectedProvider(item)} data-bs-toggle="modal" data-bs-target="#DeleteProvider" className="btn p-0  eye-delete">
															<img src={DeleteButton} alt="Delete" width={18} height={20} />
														</button>
													</li> */}
												</ul>
											</td>
										</tr>
									))}
								{providers.items?.length === 0 &&
									<tr>
										<td colSpan={12} className="text-center">
											No record found
										</td>
									</tr>
								}
							</tbody>

						</table>
					</div>

					{providers?.pagination?.total_pages > 1 &&
						<div className="card-footer space-between align-middles">
							<div className="pagination-wrap">
								<Pagination
									total_pages={providers?.pagination?.total_pages}
									current_page={providers?.pagination?.current_page}
									onClick={(i: { [key: string]: number }) =>
										setFilterData((prev) => ({
											...prev,
											page: i.selected + 1
										}))
									}
								/>

							</div>

							<div className="space-between align-middles gap-3">
								<span>Showing {providers?.items?.length} to {providers?.pagination?.total_items}</span>
								<div className="data-entry">
									<select className="form-select"
										value={filterData.page}
										onChange={(event: any) =>
											setFilterData((prev) => ({
												...prev,
												page: 1, // Reset to first page when size changes,
												size: event.target.value
											}))
										}>

										<option selected value="1">Show 10</option>
										<option value="20">20</option>
										<option value="30">30</option>
										<option value="40">40</option>
									</select>
								</div>
							</div>
						</div>
					}
				</div>

				<AddEditProvider getProviders={getProviders} selectedProvider={selectedProvider} />
				<DeleteProvider getProviders={getProviders} selectedProvider={selectedProvider} />
			</div>
		</>
	)
}
export default Providers;