import React, { useEffect, useState } from 'react'

import './style.scss'
import { DeleteButton, EditButton, EyeButton, Grap, Providers, User, Vacancy } from './assets/icons'
import { bookingService } from 'services'
import { _Object, calandarDateFormat, capitalize, capitalizeFirstLetter, formatTime } from 'utils/common-functions'
import AddEditBooking from './booking/add-edit-booking'
import BookingDetails from 'views/components/booking-details'
import { Link } from 'react-router-dom'
import { SEOHeader } from 'views/components'

const DashBoard = () => {
	const [bookings, setBookings] = useState([])
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedBooking, setSelectedBooking] = useState<any>('');
	const [view, setView] = useState<any>('');
	useEffect(() => {
		setLoading(true)
		bookingService.getBookings().then((data: _Object) => {
			setBookings(data.items)
			setLoading(false)
		})
	}, [])

	const deleteItem = (id: string) => {
		if (window.confirm('Are you sure to delete this item?')) {
			setLoading(true);
			bookingService.deleteBooking(id).then((data: _Object) => {
				!data.error
				setLoading(false);
			});
		}
	};
	return (
		<>
			<SEOHeader title="Dashboard" />
			<section className="dashboard-section">
				<div className="row">
					<div className="col-3">
						<div className="chart-card">
							<div className="card">
								<p>
									<img src={Providers} alt="Providers" width={15} height={15} />&nbsp;

									Total Appointment</p>
								<h4>5665656</h4>
								<a href="#"> <span>25%</span>25 days</a>
							</div>
						</div>
					</div>

					<div className="col-3">
						<div className="chart-card">
							<div className="card">
								<p><img src={User} alt="User" width={15} height={15} />&nbsp; Total Patient</p>
								<h4>5665656</h4>
								<a href="#"> <span>25%</span>25 days</a>
							</div>
						</div>
					</div>

					<div className="col-3">
						<div className="chart-card">
							<div className="card">
								<p><img src={Vacancy} alt="User" width={15} height={15} />&nbsp; Total Vacancy</p>

								<h4>5665656</h4>
								<a href="#"> <span>25%</span>25 days</a>

							</div>
						</div>
					</div>

					<div className="col-3">
						<div className="chart-card">
							<div className="card">
								<p><img src={User} alt="User" width={15} height={15} />&nbsp; Total Doctors</p>

								<h4>5665656</h4>
								<a href="#"> <span>25%</span>25 days</a>

							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-8">
						<div className="patient-visited">
							<div className="card">
								<div className="card-header">
									<div className="patient-hedding">
										<h5>
											Patient Visient
										</h5>
									</div>
									<div className="patient-list">
										<ul className="nav nav-pills" id="pills-tab" role="tablist">
											<li className="nav-item" role="presentation">
												<button className="nav-link active" id="Monthly-tab" data-bs-toggle="pill" data-bs-target="#Monthly" type="button" role="tab" >Monthly</button>
											</li>

											<li className="nav-item" role="presentation">
												<button className="nav-link" id="Weekly-tab" data-bs-toggle="pill" data-bs-target="#Weekly">Weekly</button>
											</li>

											<li className="nav-item" role="presentation">
												<button className="nav-link" id="Today-tab" data-bs-toggle="pill" data-bs-target="#Today" type="button" role="tab" >Today</button>
											</li>

										</ul>

									</div>
								</div>
								<div className="card-body">
									<div className="tab-content" id="pills-tabContent">
										<div className="tab-pane fade show active" id="Monthly" role="tabpanel">

											<img src={Grap} alt="Grap" className="img-fluid" />

										</div>
										<div className="tab-pane fade" id="Weekly" role="tabpanel">Cooming Soon</div>
										<div className="tab-pane fade" id="Today" role="tabpanel">Cooming Soon</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-4">
						<div className="patient-visited">
							<div className="card">
								<div className="card-header border-0">
									<div className="patient-hedding">
										<h5>
											Patient Visient
										</h5>
									</div>

								</div>
								<img src={Grap} alt="Grap" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="appointment-table">
						<div className="col-12">
							<div className="card">
								<div className="card-header space-between align-middles pb-10">
									<h5 className="mb-0">Bookings</h5>
									<Link to="/app/bookings" className="btn btn-link">View All</Link>
								</div>
								<div className="card-body p-0">
									<table className="table">
										<thead>
											<tr>
												<th>Booking #</th>
												<th>Customer</th>
												<th>Booking Date</th>
												<th>Status</th>
												<th>Service</th>
												<th className="action-button">Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												<tr>
													<td colSpan={12} className="text-center">
														<div className="d-flex justify-content-center">
															<div className="spinner-border text-secondary" role="status">
															</div>
														</div>
													</td>
												</tr> :
												bookings?.map((item: any, i: number) => {
													const today = new Date();
													const bookingDate = new Date(item.booking_date);
													return (
														<>
															{i <= 4 &&
																<tr key={i}>
																	<td>{item.booking_number}</td>
																	<td>
																		<p className="mb-1"><b>Name:</b> {capitalize(item.customer_name) || '-'}</p>
																		<a href={`mailto:${item.customer_email}`}>
																			<p className="mb-1"><b>Email:</b> {item.customer_email || '-'}</p>
																		</a>
																		<a href={`tel:${item.customer_phone1}`}>
																			<p className="mb-1"><b>Phone no:</b> {item.customer_phone1 || '-'}</p>
																		</a>
																	</td>
																	<td>
																		<p className="mb-1"><b>Date:</b> {calandarDateFormat(item?.booking_date)}</p>
																		<p className="mb-1"><b>Time:</b> {`${formatTime(item.start_time)} - ${formatTime(item.end_time)}` || '-'}</p>
																	</td>
																	<td>
																		<p className="mb-1">
																			{capitalizeFirstLetter(item?.service.title) || '-'}
																		</p>
																	</td>

																	<td>
																		<p className="mb-1">{capitalizeFirstLetter(item.status) || '-'}</p>
																	</td>
																	<td>
																		<ul className="list-inline">
																			{item.booking_date && bookingDate <= today && (<li className="list-inline-item">
																				<a href="#" className="eye-edit" type="button" data-bs-toggle="modal" data-bs-target="#NewAppontment" onClick={() => setSelectedBooking(item?.id)}>
																					<img src={EditButton} alt="Edit" width={22} height={20} />
																				</a>
																			</li>)}
																			<li className="list-inline-item">
																				<a href="#" className="eye-button" type="button" data-bs-toggle="modal" data-bs-target="#ViewAppointment" onClick={() => setView(item)}>
																					<img src={EyeButton} alt="Eye" width={22} height={20} />
																				</a>
																			</li>
																			<li className="list-inline-item">
																				<button type="button" onClick={() => deleteItem(item.id)} className="btn p-0 eye-delete">
																					<img src={DeleteButton} alt="Delete" width={18} height={20} />
																				</button>
																			</li>
																		</ul>
																	</td>
																</tr>}
														</>
													)
												}

												)}
											{/* {bookings?.length === 0 &&
												<tr>
													<td colSpan={12} className="text-center">
														No record found
													</td>
												</tr>
											} */}
										</tbody>
									</table>
									<AddEditBooking id={selectedBooking} />
									<BookingDetails props={view} id={'ViewAppointment'} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section >
		</>
	)
}
export default DashBoard