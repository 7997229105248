import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'redux/store'

import { setLoggedInUser } from 'redux/slices/session.slice'
import { _Object, getAcronym } from 'utils/common-functions'
import './style.scss'
// import { Notification } from './assets/icons'

const Header = () => {
	const dispatch = useDispatch<AppDispatch>()

	const { loggedInUser } = useSelector((state: _Object) => state.session)

	useEffect(() => {
		!loggedInUser?.id && dispatch(setLoggedInUser())
	}, [])

	return (
		<header className="main-header">
			<div className="space-between align-middles">

				<h6>
				</h6>

				<ul className="list-inline mb-0">
					<li className="list-inline-item">

						<div className="dropdown user-profile">
							{loggedInUser.name && <span className="avtar-user">{getAcronym(loggedInUser.name)}</span>}{loggedInUser.name}
						</div>
					</li>
				</ul>

			</div>
		</header>
	)
}

export default Header
