import CommonService from './common.service';

class CustomerService extends CommonService {
	async getBusinessDetails(id: string) {
		return await this.get(`business-details?company=${id}`)
	}

	async getServices(id: string) {
		return await this.get(`services?company=${id}`)
	}

	async getSlots(company_id: string, date: string, service_id: string) {
		return await this.get(`slots?date=${date}&service_id=${service_id}&company=${company_id}`)
	}

	async createBooking(company_id: string, params: { [key: string]: boolean | string }) {
		return await this.post(`bookings?company=${company_id}`, params)
	}
}
export const customerService = new CustomerService();
