import React, { FocusEvent } from 'react';
import { _Object } from 'utils/common-functions';
import Select, { StylesConfig, Props as SelectProps } from 'react-select';

interface SelectFieldProps extends _Object {
	label?: string;
	name?: string;
	value?: any;
	onChange?: (value: any, actionMeta: any) => void; // Adjust actionMeta type as needed
	options?: any[]; // Use 'any' for options
	onBlur?: (e: FocusEvent<any, Element>) => void; // Update onBlur prop
	getOptionLabel?: (option: any) => string; // Use 'any' for getOptionLabel
	getOptionValue?: (option: any) => string; // Use 'any' for getOptionValue
	disabled?: boolean;
	required?: boolean;
	className?: string;
	placeholder?: string;
	error?: any;
}

const SelectField: React.FC<SelectFieldProps & SelectProps<any>> = (props: SelectFieldProps & SelectProps<any>) => {
	const isSelected = () => {
		if (props.value && typeof props.value === 'object' && !Array.isArray(props.value)) {
			const flag = Object.keys(props.value)[0];
			if (props.options && props.options[0]?.options) {
				return props.options[0]?.options?.filter(
					(item: { [key: string]: string | number }) => props.value[flag].includes(item[flag])
				);
			} else {
				return props.options && props.options?.filter(
					(item: { [key: string]: string | number }) => props.value[flag] === item[flag]);
			}
		}
		return props.value;
	};

	const customStyles: StylesConfig = {
		control: (provided: Record<string, unknown>) => ({
			...provided,
			border: props.error && '1px solid #FF0000'
		})
	};

	return (
		<div className={`form-group ${props.className} ${props.error && 'invalid'}`}>
			{props.label && (
				<label className="form-label">
					{props.label} {props.required && <span className="text-danger">*</span>}
				</label>
			)}
			<Select
				{...props}
				className={'react-dropdown'}
				classNamePrefix="react-select"
				menuPlacement="auto"
				styles={customStyles}
				name={props.name}
				required={false}
				placeholder={props.placeholder || 'Select option'}
				isDisabled={props.disabled}
				options={props.options}
				value={isSelected()}
				onChange={props.onChange}
				onBlur={props.onBlur}
				onMenuClose={() => {
					document.getElementById('root')?.click();
				}}
				getOptionLabel={props.getOptionLabel}
				getOptionValue={props.getOptionValue}
			/>
			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default SelectField;
