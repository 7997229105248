import moment from 'moment';
import { routes } from './routes';

export {
	routes
}

export interface _Object {
	[key: string]: any
}

export const generateQueryParams = (args: any) => {
	let str = ''
	let replace = ''
	for (const key in args) {
		if (typeof (args[key]) === 'object') {
			for (const key1 in args[key]) {
				str += `&filters[${key1}]=${args[key][key1]}`
				replace = str.slice(1)
			}
		} else {
			str += `&${key}=${args[key]}`
			replace = str.slice(1)
		}
	}
	return replace
}

export const serialNumber = (per_page: number, page: number, i: number) => {
	return ((per_page * page) - per_page + 1) + i
}

export const capitalizeFirstLetter = (name = '') => {
	const firstLetter = name?.charAt(0).toUpperCase()
	const restOfName = name?.slice(1).toLowerCase()
	const capitalizedUserName = firstLetter + restOfName
	return capitalizedUserName;
}

export const capitalize = (params: string) => {
	if (!params) return '-'
	const firstLetter = `${params}`
	return firstLetter.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

export const doDateFormat: any = (date: string, format = 'YYYY-MM-DD') => {
	return moment(date).format(format)
}

export const closeModal = (selector = 'new-client') => {
	const closeButton = document.querySelectorAll(`#${selector} .btn-close`)

	if (closeButton[0] instanceof HTMLElement) { // https://stackoverflow.com/questions/46204003/trigger-click-in-typescript-property-click-does-not-exist-on-type-element
		closeButton[0]?.click()
	}
}

export const convertToTitleCase = (str: string) => {
	// Check if the string contains the word "Admin"
	if (str.toLowerCase().includes('admin')) {
		// If "Admin" is found, add a hyphen before it
		str = str.replace(/(?:^|_)\w/g, function (match) {
			return match.toUpperCase();
		}).replace(/_/g, ' ').replace(/\bAdmin\b/g, '- Admin');
	} else {
		// Otherwise, proceed with the regular title case conversion
		str = str.replace(/(?:^|_)\w/g, function (match) {
			return match.toUpperCase();
		}).replace(/_/g, ' ');
	}
	return str;
};

export const getAcronym = (inputString: string) => {
	if (inputString) {
		const words = inputString?.split(' ');
		const acronym = words.map(word => word.charAt(0).toUpperCase()).join('');
		return acronym;
	}
};

export const templateVars = [
	{ label: 'service', description: 'value of field "Service"' },
	{ label: 'service_description', description: 'value of field "Service description"' },
	{ label: 'provider', description: 'value of field "Service provider"' },
	{ label: 'provider_description', description: 'value of field "Service provider description"' },
	{ label: 'date_start', description: 'value of field "Service date"' },
	{ label: 'time_start', description: 'value of field "Service time"' },
	{ label: 'date_end', description: 'value of field "Service end date"' },
	{ label: 'time_end', description: 'value of field "Service end time"' },
	{ label: 'service_duration', description: 'value of field "Service duration"' },
	{ label: 'buffertime_after', description: 'value of field "Buffer time after"' },
	{ label: 'week_day_start', description: 'value of field "Weekday"' },
	{ label: 'week_day_start_short', description: 'value of field "Weekday short name"' },
	{ label: 'client_timezone', description: 'value of field "Client timezone"' },
	{ label: 'company_timezone', description: 'value of field "Company timezone"' },
	{ label: 'date_list', description: 'value of field "Recurring appointments dates"' },
	{ label: 'client', description: 'value of field "Client name"' },
	{ label: 'client_first_name', description: 'value of field "Client first name"' },
	{ label: 'client_phone', description: 'value of field "Client phone"' },
	{ label: 'client_email', description: 'value of field "Client email"' },
	{ label: 'client_address', description: 'value of field "Client address"' },
	{ label: 'client_bookings_link', description: 'By clicking this link the client can see a list of all events he	has booked' },
	{ label: 'code', description: 'value of field "Appointment code"' },
	{ label: 'code_list', description: 'value of field "Recurring event codes"' },
	{ label: 'cancel_link', description: 'Cancel event link' },
	{ label: 'twitter_link', description: 'Twitter link' },
	{ label: 'feedback_link', description: 'Feedback link' },
	{ label: 'company', description: 'Company name' },
	{ label: 'link_to_set_password', description: 'Link to set the password' },
	{ label: 'username', description: 'User name' },
	{ label: 'admin_link', description: 'Admin interface link' }
]

export const timeOptions = () => {
	const options = [];

	for (let hour = 0; hour < 24; hour++) {
		for (let minute = 0; minute < 60; minute += 15) {
			const period = hour < 12 ? 'am' : 'pm';
			const adjustedHour = hour % 12 || 12; // Adjust hour for 12-hour format, converting 0 to 12
			const time = `${String(adjustedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
			const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`;

			options.push({ label: time, value: value });
		}
	}

	return options;
};

export const calandarDateFormat: any = (date: string) => {
	return moment(date).format('dddd, MMMM DD')
}

export const formatTime = (timeString: any) => {
	if (!timeString) {
		return
	}
	const [hours, minutes] = timeString.split(':').map(Number);
	const ampm = hours >= 12 ? 'pm' : 'am';
	const formattedHours = hours % 12 || 12;
	return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
};

export const getSixMonthsIncludingCurrent = () => {
	const currentMonthIndex = moment().month(); // Moment.js months are 0-indexed
	const currentYear = moment().year(); // Get the current year

	const months = moment.months(); // ['January', 'February', ..., 'December']

	// Create a new array with the current month and the next five months, including the year
	const monthsIncludingCurrent = [];
	for (let i = 0; i < 6; i++) {
		const monthIndex = (currentMonthIndex + i) % 12;
		const year = currentYear + Math.floor((currentMonthIndex + i) / 12);
		monthsIncludingCurrent.push(`${months[monthIndex]} (${year})`);
	}

	return monthsIncludingCurrent;
};

// Function to format date for Google Calendar (yyyyMMddTHHmmssZ)
export const formatDateGoogle = (date: string, time: string) => {
	const dt = new Date(`${date} ${time}`);
	return dt.toISOString().replace(/-|:|\.\d+/g, '');
};

// Function to format date for Yahoo Calendar (yyyy-MM-ddTHH:mm:ssZ)
export const formatDateYahoo = (date: string, time: string) => {
	const dt = new Date(`${date} ${time}`);
	return dt.toISOString().replace(/-|:|\.\d+/g, '').slice(0, -1);
};