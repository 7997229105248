import { routes } from 'utils/routes';
import { Booking, BookingAddEdit, CreateSchedule, DashBoard, EmailSetting, Profile, Provider, Schedule, Service } from 'views/pages';
import { ChangePassword } from 'views/pages/auth';

const PrivateRoutes = [
	{ path: `${routes.dashboard}`, exact: true, name: 'DashBoard', component: DashBoard },
	{ path: `${routes.schedule}`, exact: true, name: 'Schedules', component: Schedule },
	{ path: `${routes.provider}`, exact: true, name: 'Providers', component: Provider },
	{ path: `${routes.service}`, exact: true, name: 'Services', component: Service },
	{ path: `${routes.booking}`, exact: true, name: 'Bookings', component: Booking },
	{ path: `${routes.emailSetting}`, exact: true, name: 'Email Settings', component: EmailSetting },
	{ path: `${routes.createSchedule}`, exact: true, name: 'Creae', component: CreateSchedule },
	{ path: `${routes.changePassword}`, exact: true, name: 'Change Password', component: ChangePassword },

	{ path: `${routes.profile}`, exact: true, name: 'Profile', component: Profile },
	{ path: `${routes.booking}/create`, exact: true, name: 'Bookings Create', component: BookingAddEdit },
	{ path: `${routes.booking}/edit/:id`, exact: true, name: 'Bookings Edit', component: BookingAddEdit }
];

export default PrivateRoutes;
