import React, { useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, InputField } from 'views/stories'

import { authService } from 'services'
import { Logo } from 'assets/images'
import { SEOHeader } from 'views/components'
import { routes } from 'utils/common-functions'
import { ArrowLeft, HandMobile } from '../images'
import { Link } from 'react-router-dom'
import Alert from 'views/components/alerts'

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			email: Yup.string().label('Email').required().email()
		}),

		onSubmit: async (values) => {
			setError({})
			setLoading(true)
			authService.forgotPassword(values)
				.then((data) => {
					setError(data)
					setLoading(false)
				})
		}
	})

	return (
		<>
			<SEOHeader title="Forgot Password" />
			<div className="col-lg-6 col-md-12">
				<div className="login-form-col">
					<div className="brand-logo">

						<img src={Logo} alt="Logo" className="brand-logo-images" />

						<h4>Forgot Your Password?</h4>
						<p>Enter your email to receive instructions to reset password.</p>
					</div>

					<form onSubmit={formik.handleSubmit}>
						<InputField
							type="email"
							name="email"
							label="Email"
							required={true}
							// placeholder="Enter here"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik?.touched.email && formik.errors.email || ''}
						/>

						<Button name="Send" type="submit"
							// className={'btn btn-success mt-1'}
							label="Reset password"
							className={`btn btn-success mt-1 ${loading ? 'btn-loading' : ''} `}
							disabled={loading} />
						{/* <span className="btn-text">{loading ? 'Loading...' : 'Reset password'}</span> */}
					</form>

					<div className="login-link-button">
						<p>
							<Link className="btn btn-link" to={routes.login}>
								<img src={ArrowLeft} alt="Arrow Left" height={13} width={13} /> Back to login</Link>
						</p>
					</div>
				</div>
				{error.message && <Alert {...error} />}
			</div>

			<div className="col-lg-6 col-md-12">
				<div className="hand-moblie-col">
					<img src={HandMobile} alt="Hand Mobile" />
				</div>
			</div>
		</>
	)
}
export default ForgotPassword