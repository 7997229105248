import React from 'react'

import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Logo } from 'assets/images'
import { destroyAuthSession } from 'redux/slices/session.slice'
import { Booking, ChangePassword, Schedule, Dashboard, EditProfile, EmailNotification, Providers, Service, Logout } from './assets/icons'
import { routes } from 'utils/routes'
import './style.scss'

const Sidebar = () => {
	const dispatch = useDispatch()

	return (
		<>
			<div id="sidebar" className="sidebar-colums">

				<div className="main-logo">
					<NavLink to="/app/dashboard" className="d-inline-block">
						<img src={Logo} alt="Logo" className="img-fluid" width={180} height={41} />
					</NavLink>
				</div>

				<div className="sidebar-inner">
					<ul className="list-unstyled">
						<h6>Main menu</h6>

						<li>
							<NavLink className="nav-link" to={routes.dashboard}>
								<span>
									<img src={Dashboard} alt="Dashboard" width={15} height={15} />
								</span>
								Dashboard
							</NavLink>
						</li>

						<li>
							<NavLink className="nav-link" to={routes.booking}>
								<span>
									<img src={Booking} alt="Booking" width={15} height={15} />
								</span>
								Bookings
							</NavLink>
						</li>

					</ul>

					<ul className="list-unstyled">
						<h6>Settings</h6>

						<li>
							<NavLink className="nav-link" to={routes.schedule}>
								<span>
									<img src={Schedule} alt="Schedule" width={15} height={15} />
								</span>
								Schedules
							</NavLink>
						</li>

						<li>
							<NavLink className="nav-link" to={routes.emailSetting}>
								<span>
									<img src={EmailNotification} alt="Notification" width={15} height={15} />
								</span>
								Email Notifications
							</NavLink>
						</li>

						<li>
							<NavLink className="nav-link" to={routes.service}>
								<span>
									<img src={Service} alt="Services" width={18} height={15} />
								</span>
								Services
							</NavLink>
						</li>

						<li>
							<NavLink className="nav-link" to={routes.provider}>
								<span>
									<img src={Providers} alt="Providers" width={15} height={15} />
								</span>
								Providers
							</NavLink>
						</li>

					</ul>

					<ul className="list-unstyled">
						<h6>Account</h6>

						<li>
							<NavLink className="nav-link" to="/app/profile">
								<span>
									<img src={EditProfile} alt="Edit Profile" width={15} height={15} />
								</span>
								Edit Profile
							</NavLink>
						</li>

						<li>
							<NavLink className="nav-link" to="/app/change-password">
								<span>
									<img src={ChangePassword} alt="Change Password" width={15} height={15} />
								</span>
								Change Password
							</NavLink>
						</li>
					</ul>
				</div>

				<div className="logout-col">
					<button
						className="btn"
						onClick={() => dispatch(destroyAuthSession())}>
						<span>
							<img src={Logout} alt="Logout" width={15} height={15} />
						</span>
						Logout
					</button>
				</div>
			</div>
		</>
	)
}

export default Sidebar