import React, { ChangeEvent, FocusEvent, ReactElement } from 'react';

interface TextAreaProps {
	label?: string;
	name?: string;
	value?: string;
	rows?: number;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
	disabled?: boolean;
	placeholder?: string;
	required?: boolean;
	className?: string;
	error?: string;
}

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps): ReactElement => {
	return (
		<div className={`form-group mb-3 ${props.className}  ${props.error && 'invalid'}`}>
			{props.label && <label className="form-label">{props.label} {props.required && <span className="text-danger">*</span>}</label>}

			<textarea
				className={'form-control'}
				name={props.name}
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
				rows={props.rows}
				disabled={props.disabled}
				onBlur={props.onBlur}
			/>
			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default TextArea;
