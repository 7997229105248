import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { bookingService, syncTimeService } from 'services';
import { _Object, calandarDateFormat, closeModal, doDateFormat, formatTime, routes } from 'utils/common-functions';
import { Button, DateField, InputField, PhoneNumberField, SelectField, TextArea } from 'views/stories';
import { SEOHeader } from 'views/components';

const initialValue = {
	customer_name: '',
	customer_note: '',
	customer_email: '',
	customer_phone1: '',
	status: 'confirmed',
	start_time: '',
	end_time: '',
	booking_date: doDateFormat(new Date()),
	internal_note: '',
	service_id: '',
	service_label: ''
};

const AddEditBooking = (selectedBooking: any) => {
	const navigate = useNavigate();

	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [services, setServices] = useState<_Object>({});
	const [startDate, setStartDate] = useState<any>();
	const [bookingSlots, setBookingSlots] = useState<any>([]);
	const [slotError, setSlotError] = useState<any>([]);
	console.log('slotError', slotError.error === true);

	const [initialData, setInitialData] = useState<{ [key: string]: string }>(initialValue);
	const [activeServiceId, setActiveServiceId] = useState<any>(null);

	const formik = useFormik({
		initialValues: initialData,
		enableReinitialize: true,
		validationSchema: Yup.object({
			customer_name: Yup.string().label('Customer name').required(),
			customer_email: Yup.string().label('Customer email').required(),
			customer_phone1: Yup.string()
				.matches(/^(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number format')
				.required('Phone number is required'),
			booking_date: Yup.string().label('Booking date').required(),
			service_id: Yup.string().label('Service').required(),
			service_label: Yup.string().label('Service').required(),
			start_time: Yup.string().label('Start time').required()
		}),
		onSubmit: (values) => {
			setLoading(true);
			if (selectedBooking.id) {
				delete values?.payment_status
				delete values?.secret_key
				delete values?.booking_number
				delete values?.created_at
				delete values?.updated_at
				delete values?.client_id
				delete values?.provider_id
				delete values?.service_id
				bookingService.updateBooking(selectedBooking.id, values).then((data) => {
					if (!data.error) {
						selectedBooking.getBookings()
						formik.resetForm()
						setCurrentStep(1)
						setBookingSlots([])
						closeModal('NewAppontment')
						toast.success(data?.message);
						navigate(`${routes.booking}`);
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			} else {
				bookingService.createBooking(values).then((data: _Object) => {
					if (!data.error) {
						selectedBooking.getBookings()
						toast.success(data?.message);
						setCurrentStep(1)
						setBookingSlots([])
						formik.resetForm()
						closeModal('NewAppontment')
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			}
		}
	});
	console.log('formik.values.booking_date', formik.values.booking_date);

	useEffect(() => {
		getServices();
		if (selectedBooking?.id) {
			bookingService.getBookingDetails(selectedBooking.id).then((data: _Object) => {
				const bookingDate: any = new Date(data.booking_date);
				formik.setFieldValue('booking_date', bookingDate)
				setStartDate(bookingDate);
				setInitialData(data);
				bookingService.getBookingSlots({ date: data.booking_date, service_id: data.service_id }).then((slot) => {
					console.log('slot>>>', slot.message === 'Booking date must be today or a future date');
					setSlotError(slot)
					setLoading(false)
					if (slot.length === 0) {
						toast.error('Slot is not available, please choose another date.', { autoClose: 2000 });
					}
					setBookingSlots([...slot, {
						endTime: data?.end_time,
						startTime: data?.start_time
					}]);
					setLoading(false)
				})
			});
			setLoading(true)
		} else {
			setInitialData(initialValue);
		}
	}, [selectedBooking?.id]);

	const getServices = () => {
		syncTimeService.getServices().then((data: _Object) => {
			if (data.items) setServices(data);
		});
	};

	const handleServiceClick = (serviceId: string, serviceLabel: string) => {
		setActiveServiceId(serviceId);
		setLoading(true)
		bookingService.getBookingSlots({ date: formik.values.booking_date, service_id: serviceId }).then((data) => {
			if (data.length === 0) {
				toast.error('Slot is not available, please choose another date.', { autoClose: 2000 });
			}
			setBookingSlots(data);
			setLoading(false)
		})
		formik.setFieldValue('service_id', serviceId);
		formik.setFieldValue('service_label', serviceLabel);
	};

	const handleNext = () => {
		setCurrentStep(currentStep + 1);
	};

	const handlePrevious = () => {
		setCurrentStep(currentStep - 1);
	};
	console.log('fomik')
	return (
		<>
			<SEOHeader title={`Booking ${selectedBooking?.id ? 'Edit' : 'New'}`} />
			<div className="modal fade" id="NewAppontment" aria-labelledby="NewAppontment" aria-hidden="true" data-bs-backdrop="static">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0"> {selectedBooking?.id ? 'Edit' : 'New'} Booking</h5>
								<button type="button" className="btn-close"
									onClick={() => { formik.resetForm(); setBookingSlots([]); setActiveServiceId([]); setCurrentStep(1); setStartDate(new Date) }}
									data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">
								{currentStep === 1 && (
									<div className="booking-step-1">
										<div className="row">
											<div className="col-4">
												<div className="services-step-active">
													<h6>Services</h6>
													{services?.items?.map((item: { id: string; title: string, status: string, label: string }) => (
														<div key={item.id}>
															{item?.status === 'active' &&
																<Button
																	type="button"
																	onClick={() => handleServiceClick(item.id, item.title)}
																	className={`btn btn-outline-success mb-2 ${item?.id === activeServiceId || item.id === formik?.values?.service_id ? 'active' : ''}`}>{item.title}</Button>}
														</div>
													))}
												</div>
											</div>
											<div className="col-4">
												<h6>Select Date</h6>
												<DateField
													required={true}
													open={false}
													name="booking_date"
													className="col-12"
													value={startDate || null}
													inline={true}
													onChange={async (date: any) => {
														setStartDate(date);
														formik.setFieldValue('booking_date', doDateFormat(date));
														setLoading(true)
														await bookingService.getBookingSlots({ date: doDateFormat(date), service_id: formik.values.service_id }).then((data) => {
															if (data?.length === 0) {
																toast.error('Slot is not available, please choose another date.', { autoClose: 2000 });
															}
															setBookingSlots(data);
															setLoading(false)
														})
													}}
													minDate={new Date()}
													dateFormat="MMMM dd, yyyy"
													error={(formik.touched.booking_date && formik.errors.booking_date) || ''}
												/>
											</div>
											<div className="col-4">
												{loading ? (
													<div className="spinner-border" role="status">
														<span className="visually-hidden">Loading...</span>
													</div>
												) : (
													<div className="booking-slots">
														<h6>
															{calandarDateFormat(startDate)}
														</h6>
														{bookingSlots.length > 0 ? bookingSlots.map((item: any, index: number) => (
															<div key={index}>
																<Button
																	type="button"
																	className={`btn btn-outline-success mb-2 ${(
																		formik.values.start_time === `${item.startTime}:00` ||
																		formik.values.start_time === item.startTime ||
																		formik.values.end_time === `${item.endTime}:00` ||
																		formik.values.end_time === item.endTime
																	) ? 'active' : ''}`}
																	onClick={() => {
																		formik.setFieldValue('start_time', item.startTime);
																		formik.setFieldValue('end_time', item.endTime);
																	}}
																>
																	{`${formatTime(item.startTime)} - ${formatTime(item.endTime)}`}
																</Button>
															</div>
														)) :
															formik?.values?.start_time && <div className="btn btn-outline-success mb-2">
																{`${formik?.values && formatTime(formik?.values?.start_time)} - ${formik?.values && formatTime(formik?.values?.end_time)}`}
																{!formik?.values?.start_time && formik?.values?.end_time && 'No records found'}
															</div>
														}

													</div>
												)}
											</div>
										</div>

										{/* <div className="row">
											<div className="col-4">
												<div className="service-placehoders">
													<p className="card-text placeholder-glow">
														<span className="placeholder col-6 mb-2"></span><br />
														<span className="placeholder service-list"></span>
														<span className="placeholder service-list"></span>
													</p>
												</div>
											</div>
											<div className="col-4">
												<p className="placeholder-glow">
													<span className="placeholder col-6 mb-2"></span><br />
												</p>
												<div className="calendar-placeholder">
													<p className="placeholder-glow mb-2 text-center">
														<span className="placeholder col-6 mb-2"></span><br />
													</p>
													<ul className="list-inline placeholder-glow calendar-list">
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>

														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
														<li className="list-inline-item placeholder"></li>
													</ul>
												</div>
											</div>
											<div className="col-4">
												<div className="service-placehoders">
													<p className="card-text placeholder-glow">
														<span className="placeholder col-6 mb-2"></span><br />
														<span className="placeholder service-list"></span>
														<span className="placeholder service-list"></span>
													</p>
												</div>
											</div>

										</div> */}
									</div>
								)}
								{currentStep === 2 && (
									<div className="row booking-step-2">
										<InputField
											className="col-6"
											type="text"
											name="customer_name"
											label="Name"
											required={true}
											placeholder="Enter here"
											value={formik.values.customer_name}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={(formik.touched.customer_name && formik.errors.customer_name) || ''}
										/>
										<InputField
											className="col-6"
											type="email"
											name="customer_email"
											label="Email"
											required={true}
											placeholder="Enter here"
											value={formik.values.customer_email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={(formik.touched.customer_email && formik.errors.customer_email) || ''}
										/>
										<PhoneNumberField
											className="col-6"
											label="Phone Number"
											required={true}
											country="in"
											value={formik.values.customer_phone1}
											onChange={(phone) => formik.setFieldValue('customer_phone1', phone || '')}
											onBlur={formik.handleBlur}
											placeholder="Enter phone number..."
											error={(formik.touched.customer_phone1 && formik.errors.customer_phone1) || ''}
										/>
										<SelectField
											className="col-6"
											name="status"
											label="Status"
											value={{ value: formik.values.status || 'confirmed' }}
											options={[
												{ label: 'Confirmed', value: 'confirmed' },
												{ label: 'Pending', value: 'pending' },
												{ label: 'Rejected', value: 'rejected' },
												{ label: 'Cancelled', value: 'cancelled' }
											]}
											onChange={(e: _Object) => formik.setFieldValue('status', e?.value || 'confirmed')}
											onBlur={formik.handleBlur}
											error={(formik.touched.status && formik.errors.status) || ''}
											isClearable
										/>
										<TextArea
											className="col-12"
											label="Note"
											name="customer_note"
											value={formik.values.customer_note}
											rows={4}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											disabled={false}
											placeholder="Enter here"
											error={(formik.touched.customer_note && formik.errors.customer_note) || ''}
										/>
									</div>
								)}
								{currentStep === 3 && (
									<div className="booking-step-3">
										{/* <h5>Summary</h5> */}
										<p><strong>Name:</strong> {formik.values.customer_name}</p>
										<p><strong>Email:</strong> {formik.values.customer_email}</p>
										<p><strong>Phone:</strong> {formik.values.customer_phone1}</p>
										<p><strong>Booking Date:</strong> {calandarDateFormat(formik.values.booking_date)}</p>
										<p className="text-capitalize"><strong>Status:</strong> {formik.values.status}</p>
										{formik.values.customer_note && <p><strong>Note:</strong> {formik.values.customer_note}</p>}
										{formik.values.service_label && <p><strong>Service:</strong> {formik.values.service_label}</p>}
										<p><b>Time Slot:</b> {formik.values.start_time && formik.values.end_time ? `${formatTime(formik.values.start_time)} - ${formatTime(formik.values.end_time)}` : '-'}</p>

									</div>
								)}
							</div>
							<div className={currentStep > 1 ? 'modal-footer d-flex justify-content-between gap-4' : 'modal-footer d-flex justify-content-end'}>
								{currentStep > 1 && (
									<Button type="button" onClick={handlePrevious} label="Previous" className="btn btn-success" loading={loading} />
								)}
								{currentStep < 3 ? (
									<>
										<Button type="button" loading={loading} onClick={handleNext} label="Next" className="btn btn-success" disabled={currentStep === 1 ? formik?.values?.service_id && formik.values.booking_date && formik.values.start_time && formik.values.end_time ? false : true : formik.values.customer_name && formik.values.customer_email && formik.values.customer_phone1 ? false : true} />
									</>
								) : (
									<Button type="submit" label={selectedBooking.id ? 'Update' : 'Submit'} className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
										disabled={loading} />
								)}
							</div>
						</form>
					</div>
				</div >
			</div >
		</>
	);
};

export default AddEditBooking;

