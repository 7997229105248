import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'redux/store'
import { setLoggedInUser } from 'redux/slices/session.slice'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, InputField, PhoneNumberField } from 'views/stories'

import { authService } from 'services'
import { SEOHeader } from 'views/components'
import { _Object } from 'utils/common-functions'
import './style.scss'
import { toast } from 'react-toastify'

const Profile = () => {
	const dispatch = useDispatch<AppDispatch>();

	const { loggedInUser } = useSelector((state: _Object) => state.session);

	useEffect(() => {
		if (loggedInUser?.id) {
			setInitialData({
				name: loggedInUser?.name || '',
				city: loggedInUser?.city || '',
				email: loggedInUser?.email,
				state: loggedInUser?.state,
				phone_number1: loggedInUser?.phone_number1,
				address2: loggedInUser?.address2,
				address1: loggedInUser?.address1
			});
		} else {
			dispatch(setLoggedInUser());
		}
	}, [loggedInUser?.id]);
	const [loading, setLoading] = useState(false)
	const [initialData, setInitialData] = useState<_Object>({
		name: loggedInUser?.name || '',
		city: loggedInUser?.city || '',
		email: loggedInUser?.email,
		state: loggedInUser?.state,
		phone_number1: loggedInUser?.phone_number1,
		address2: loggedInUser?.address2,
		address1: loggedInUser?.address1
	});

	const formik = useFormik({
		initialValues: initialData,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required('Name is required')
			// city: Yup.string().required('City is required')
		}),
		onSubmit: async (values: any) => {
			delete values.email;
			setLoading(true)
			authService.editProfile(loggedInUser?.id, values).then((result: _Object) => {
				toast.success(result?.message);
				setLoading(false)
				if (!result.error) {
					authService.getMe().then((result: _Object) => {
						setInitialData(result);
						dispatch(setLoggedInUser())
					});
				}
			});
		}
	});

	return (
		<>
			<section className="profile-section align-middles">
				<SEOHeader title="Profile" />
				<div className="container">
					<div className="row">
						<div className="col-6">
							<div className="card">
								<div className="card-header">
									<h5>Edit Profile</h5>
								</div>
								<form onSubmit={formik.handleSubmit}>
									<div className="profile-form card-body">
										<div className="row">
											<div className="col-12">
												<InputField
													label="Name"
													name="name"
													required={true}
													placeholder="Enter here"
													value={formik.values.name}
													onChange={formik.handleChange}
												/>
											</div>

											<div className="col-12">
												<InputField
													label="Email"
													name="email"
													placeholder="Enter here"
													value={formik.values.email}
													onChange={formik.handleChange}
												/>
											</div>

											{/* <div className="col-6">
												<InputField
													label="Address1"
													name="address1"
													placeholder="Enter here"
													value={formik.values.address1}
													onChange={formik.handleChange}
												/>
											</div>
											<div className="col-6">
												<InputField
													label="Address2"
													name="address2"
													placeholder="Enter here"
													value={formik.values.address2}
													onChange={formik.handleChange}
												/>
											</div> */}

											<div className="col-12">
												<PhoneNumberField
													className="custom-phone-number-field"
													label="Phone Number"
													country="us"
													value={formik.values.phone_number1}
													onChange={(phone) => {
														formik.setFieldValue('phone', phone);
													}}
													disabled={false}
													placeholder="Enter phone number..."
												/>
											</div>
											{/* <div className="col-6">
												<SelectField
													label="City"
													name="city"
													value={{ value: formik?.values?.city }}
													options={[
														{ label: 'Guna', value: 'guna' },
														{ label: 'Vidisha', value: 'vidisha' },
														{ label: 'Newyork', value: 'newyork' }
													]}
													onBlur={formik.handleBlur}
													onChange={(e) => formik.setFieldValue('city', e.value)}
													required={true}
													error={formik.touched.city && formik.errors.city || ''}
												error={(formik?.errors.city && formik.errors.city && formik.errors.city || '')}
												/>
											</div> */}
										</div>
									</div>
									<div className="card-footer border-top">
										<Button
											label="Update Profile"
											type="submit"
											className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
											disabled={loading}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Profile;
