import React, { useEffect, useState } from 'react';

import { bookingService } from 'services';
import { formatTime } from 'utils/common-functions';
import BookingDetails from './booking-details';

import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';

const BookingCalendar = ({ bookings, setBookings }: any) => {
	const [view, setView] = useState(null);

	useEffect(() => {
		bookingService?.getBookings().then((data) => {
			setBookings(data);
		});
	}, [setBookings]);

	const renderEventContent = (eventInfo: any) => {
		const data = JSON.parse(eventInfo?.event?.extendedProps?.data);
		return (
			<>
				<div
					data-bs-toggle="modal"
					data-bs-target="#exampleModal"
					onClick={() => setView(data)}
					style={{ cursor: 'pointer' }}
				>
					<ul className="list-unstyled">
						<li>
							<strong>Booking #:</strong> &nbsp;{data?.booking_number}
						</li>
						<li className="text-capitalize">
							<strong>Name:</strong>&nbsp;{data?.customer_name}
						</li>
						<li>
							<strong>Time Slot:</strong>&nbsp;{`${formatTime(data?.start_time)} - ${formatTime(data?.end_time)}`}
						</li>
					</ul>
				</div>
			</>
		);
	};

	return (
		<section className="to-do-list-section">
			<div className="container-fluid">
				<div className="row my-2">
					<div className="col-12 position-relative">
						<FullCalendar
							plugins={[dayGridPlugin]}
							initialView="dayGridMonth"
							weekends={true}
							droppable={true}
							events={bookings?.items?.map((item: any) => ({
								data: JSON.stringify(item),
								start: new Date(item.booking_date)
							}))}
							eventContent={renderEventContent}
							eventDisplay="list-item"
							moreLinkClick="popover"
							height="auto"
						/>
					</div>
				</div>
			</div>
			<BookingDetails props={view} id={'exampleModal'} />
		</section>
	);
};

export default BookingCalendar;
