import { generateQueryParams } from 'utils/common-functions';

import CommonService from './common.service';

class ProviderService extends CommonService {
	async getProviders(params?: { [key: string]: boolean | string }) {
		return await this.get(`providers/?${generateQueryParams(params)}`)
	}
	async getProviderDetails(id: string) {
		return await this.get(`providers/${id}`)
	}
	async createProvider(parmas: { [key: string]: boolean | string }) {
		return await this.post('providers', parmas)
	}
	async updateProvider(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`providers/${id}`, params)
	}
	async deleteProvider(id: string) {
		return await this.delete(`providers/${id}`)
	}
}
export const providerService = new ProviderService();
