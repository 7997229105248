import React, { useEffect, useState } from 'react'

import { Pagination, SearchField } from 'views/stories'

import { syncTimeService } from 'services'
import { _Object } from 'utils/common-functions'
import SEOHeader from 'views/components/seo-header'
import { useFormik } from 'formik'
import AddEditService from './add-edit-services'
import { EditButton, Plus } from '../assets/icons'
import DeleteService from './delete-services'

const Services = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const [services, setServices] = useState<_Object>({})
	const [selectedService, setSelectedService] = useState<_Object>({})
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		size: 10,
		sort: '-created_at',
		q: ''
	});

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '' || filterData.q !== values.q) {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});

	useEffect(() => { getServices() }, [filterData])
	const getServices = () => {
		setLoading(true)
		syncTimeService.getServices(filterData).then((data: _Object) => {
			setServices(data)
			setLoading(false)
		})
	}
	return (
		<>
			<div className="service-section">

				<SEOHeader title="Services" />

				<div className="space-between align-middles pb-10">
					<h5 className="mb-0">Services</h5>
					<button type="button" onClick={() => setSelectedService({})}
						className="btn btn-success"
						data-bs-toggle="modal"
						data-bs-target="#NewService">
						<img src={Plus} alt="Add" width={15} height={15} />&nbsp; &nbsp;Add Service
					</button>
				</div>

				<div className="card">
					<div className="card-header space-between align-middles">
						<div className="d-flex gap-3">
							<form onSubmit={formik.handleSubmit}>
								<div className="input-group">
									<SearchField
										name="q"
										value={formik.values.q}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue('q', e.target.value);
										}}
									/>
								</div>
							</form>
							{/* <Filters /> */}
						</div>

						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<a href="#">
								</a>
							</li>

							<li className="list-inline-item">
								<a href="#">
								</a>
							</li>
						</ul>
					</div>

					<form className="card-body p-0">
						<table className="table">
							<thead>
								<tr>
									<th>#</th>

									<th>
										Title
									</th>

									<th>Status</th>

									<th>Description</th>
									<th>Duration</th>
									<th>Buffer</th>

									<th className="action-button">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{loading ?
									<tr>
										<td colSpan={12} className="text-center">
											<div className="d-flex justify-content-center">
												<div className="spinner-border text-secondary" role="status">
												</div>
											</div>
										</td>
									</tr>
									: services.items?.map((item: any, i: number) => (
										<tr key={i}>
											<td>{i + 1}</td>

											<td className="text-capitalize"	>{item.title || '-'}</td>
											<td className="text-capitalize">{item.status || '-'}</td>
											<td >{item.description || '-'}</td>
											<td >{item.duration || '-'}</td>
											<td >{item.buffer || '-'}</td>
											<td>
												<ul className="list-inline">
													{/* <li className="list-inline-item">
														<a href="#" className="eye-button">
															<img src={EyeButton} alt="Eye" width={22} height={20} />
														</a>

													</li> */}
													<li className="list-inline-item">
														<button type="button" onClick={() => setSelectedService(item)} data-bs-toggle="modal" data-bs-target="#NewService" className="btn p-0 border-0 eye-edit">
															<img src={EditButton} alt="Edit" width={22} height={20} />
														</button>
													</li>
													{/* <li className="list-inline-item">
														<button type="button" onClick={() => setSelectedService(item)} data-bs-toggle="modal" data-bs-target="#DeleteService" className="btn p-0 border-0 eye-delete">
															<img src={DeleteButton} alt="Delete" width={18} height={20} />
														</button>
													</li> */}
												</ul>
											</td>
										</tr>
									))}
								{services.items?.length === 0 &&
									<tr>
										<td colSpan={12} className="text-center">
											No record found
										</td>
									</tr>
								}

							</tbody>
						</table>
					</form>

					{services?.pagination?.total_pages > 1 &&
						<div className="card-footer space-between align-middles">
							<div className="pagination-wrap">
								<Pagination
									total_pages={services?.pagination?.total_pages}
									current_page={services?.pagination?.current_page}
									onClick={(i: { [key: string]: number }) =>
										setFilterData((prev) => ({
											...prev,
											page: i.selected + 1
										}))
									}
								/>

							</div>

							<div className="space-between align-middles gap-3">
								<span>Showing {services?.items?.length} to {services?.pagination?.total_items}</span>
								<div className="data-entry">
									<select className="form-select"
										value={filterData.page}
										onChange={(event: any) =>
											setFilterData((prev) => ({
												...prev,
												page: 1, // Reset to first page when size changes,
												size: event.target.value
											}))
										}>

										<option selected value="1">Show 10</option>
										<option value="20">20</option>
										<option value="30">30</option>
										<option value="40">40</option>
									</select>
								</div>
							</div>
						</div>
					}
				</div >

				<AddEditService getServices={getServices} selectedService={selectedService} />
				<DeleteService getServices={getServices} selectedService={selectedService} />
			</div>
		</>
	)
}
export default Services