import React, { useState } from 'react';

import { Filter } from 'assets/icons';
import './style.scss';
import { Button, CheckBox, DateField } from 'views/stories';
import { _Object } from 'utils/common-functions';

const Filters = ({ onFilterChange }: _Object) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [statusFilters, setStatusFilters] = useState<string[]>([]);
	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
	};

	const handleStatusChange = (value: string) => {
		setStatusFilters((prev) =>
			prev.includes(value)
				? prev.filter((v) => v !== value)
				: [...prev, value]
		);
	};

	const handleApplyFilters = () => {
		const year = selectedDate ? selectedDate.getFullYear() : '';
		const month = selectedDate ? (`0${selectedDate.getMonth() + 1}`).slice(-2) : '';
		const day = selectedDate ? (`0${selectedDate.getDate()}`).slice(-2) : '';
		const formattedDate = selectedDate ? `${year}-${month}-${day}` : '';

		onFilterChange({
			booking_date: formattedDate,
			status: statusFilters.join(',')
		});
	};

	return (
		<div className="filter-dropdow-list">

			<button
				className="btn btn-link"
				type="button"
				data-bs-toggle="modal"
				data-bs-target="#filters-modal">
				<img src={Filter} alt="Filter" width={15} height={15} />&nbsp; &nbsp; Filters
			</button>
			<div className="modal fade" id="filters-modal" tabIndex={-1}>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h6 className="mb-0">Filters</h6>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal">
							</button>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<p>Filter by status</p>
									<ul className="list-inline">

										<li className="list-inline-item">
											<div className="form-group">
												<div className="form-check ps-0">
													<CheckBox
														options={[
															{ value: 'confirmed', label: 'Confirmed' },
															{ value: 'pending', label: 'Pending' },
															{ value: 'rejected', label: 'Rejected' },
															{ value: 'cancelled', label: 'Cancelled' }
														]}
														values={statusFilters}
														onChange={(event) => handleStatusChange(event.target.value)}
													/>
												</div>
											</div>
										</li>

									</ul>
								</div>
								<div className="col-12">
									<p>Filter by date</p>
									<DateField
										name="date"
										className="form-control"
										value={selectedDate}
										onChange={handleDateChange}
										dateFormat="yyyy-MM-dd"
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<Button
								label="Apply"
								className="btn btn-success"
								onClick={handleApplyFilters}
								data-bs-dismiss="modal"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Filters;

