import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerProps {
	label?: string;
	name?: string;
	value: Date | null;
	onChange: (value: Date | null) => void; // Adjusted the onChange signature
	dateFormat?: string;
	required?: boolean;
	error?: string;
	className?: string;
	placeholder?: string;
	classNamePreFix?: string;
	[key: string]: any
	inline?: boolean
	minDate?: Date
	open?: boolean
}

const DateField: React.FC<DatePickerProps> = (props) => {
	return (
		<div className={`form-group mb-3 date-field ${props.error && 'invalid'}`} >
			<label className="form-label">
				{/* {props.label} {props.required && <span className="text-danger">*</span>} */}
			</label>
			<DatePicker
				className="date-picker-show"
				open={props?.open}
				selected={props.value}
				onChange={props.onChange}
				dateFormat={props.dateFormat}
				isClearable
				placeholderText={props.placeholder || props.dateFormat}
				customInput={<input className="form-control" />}
				inline={props.inline ? true : false}
				minDate={props?.minDate}
			/>
			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
}
export default DateField;
