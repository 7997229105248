import React from 'react'

import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { _Object } from 'utils/types'

const SEOHeader = (props: _Object) => {
	const { title } = props
	const { globalSettings } = useSelector((state: _Object) => state.session)
	console.log('globalSettings?.store_name', globalSettings);
	console.log('title', title);

	return (
		<Helmet>
			<title>{title} {globalSettings?.store_name ? `- ${globalSettings.store_name}` : '| SyncTime'}</title>
		</Helmet>
	)
}
export default SEOHeader