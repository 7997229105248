import React, { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { authService } from 'services'

import { _Object, routes } from 'utils/common-functions'
import { Button, PasswordField } from 'views/stories'
import { SEOHeader } from 'views/components'
import { Logo } from 'assets/images'
import { ArrowLeft, HandMobile } from '../images'
import Alert from 'views/components/alerts'

const ResetPassword = () => {
	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const token = query.get('token')

	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)

	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})

	const formik = useFormik({
		initialValues: {
			new_password: '',
			confirm_password: '',
			token: token
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			new_password: Yup
				.string()
				.required('Password is required')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
					'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character, space not allowed.'
				),

			confirm_password: Yup
				.string()
				.required('Confirm password is required')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
					'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character, space not allowed.'
				).oneOf([Yup.ref('new_password')], 'Passwords must match')

		}),

		onSubmit: async (values) => {
			setLoading(true)
			setError({})
			authService.updatePassword(values)
				.then((data: _Object) => {
					formik.resetForm();
					setError(data)
					data.error === false && navigate(routes.login)
					setLoading(false)
				})
		}
	})

	return (
		<>
			<SEOHeader title="Reset Password" />
			<div className="col-lg-6 col-md-12">
				<div className="login-form-col">
					<div className="brand-logo">
						<img src={Logo} alt="Logo" />
						<h4>Reset Your Password</h4>
						<p>Enter your new password to regain access to your account. </p>
					</div>

					<form onSubmit={formik.handleSubmit}>
						<PasswordField
							name="new_password"
							label="Password"
							required={true}
							// placeholder="Enter here"
							value={formik.values.new_password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik?.touched.new_password && formik.errors.new_password || ''}
						/>

						<PasswordField
							name="confirm_password"
							label="Confirm password"
							required={true}
							// placeholder="Enter here"
							value={formik.values.confirm_password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik?.touched.confirm_password && formik.errors.confirm_password || ''}
						/>

						<Button type="submit" label="Reset password" className={`btn btn-success mt-1 ${loading ? 'btn-loading' : ''} `}
							disabled={loading} />

						<div className="login-link-button">
							<a className="btn btn-link" href="/login">
								<img src={ArrowLeft} alt="Arrow Left" height={13} width={13} /> Back to login
							</a>
						</div>

					</form>
				</div>
				{error.message && <Alert {...error} />}
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="hand-moblie-col">
					<img src={HandMobile} alt="Hand Mobile" />
				</div>
			</div>
		</>

	)
}
export default ResetPassword;