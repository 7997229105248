import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { SEOHeader } from 'views/components'
import { Button, InputField, PasswordField } from 'views/stories'
import { authService } from 'services'
import { _Object, routes } from 'utils/common-functions'
import '../style.scss'

const ChangePassword = () => {
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)

	const formik = useFormik({
		initialValues: {
			current_password: '',
			new_password: '',
			confirm_password: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			current_password: Yup.string().label('Current Password').required(),
			new_password: Yup
				.string()
				.label('New Password')
				.required()
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
					'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character'
				),
			confirm_password: Yup
				.string()
				.label('Confirm Password')
				.required()
				.oneOf([Yup.ref('new_password')], 'Passwords must match')
		}),

		onSubmit: async (values) => {
			setLoading(true)
			authService.changePassword(values)
				.then((data: _Object) => {
					if (data.error === false) {
						navigate(`${routes.login}`)
					}
					setLoading(false)
				})
		}
	})

	return (
		<>
			<section className="change-passwrod-section align-middles">
				<SEOHeader title="Change password" />
				<div className="container">
					<div className="row">
						<div className="col-6">
							<div className="card">
								<div className="card-header">
									<h5>Change Password</h5>
								</div>
								<form onSubmit={formik.handleSubmit}>
									<div className="login-password-field card-body">
										<PasswordField
											name="current_password"
											label="Current Password"
											required={true}
											placeholder="Enter here"
											value={formik.values.current_password}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik?.touched.current_password && formik.errors.current_password || ''}
										/>
										<div className="login-password-field">
											<PasswordField
												name="new_password"
												label="New Password"
												required={true}
												placeholder="Enter here"
												value={formik.values.new_password}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={formik?.touched.new_password && formik.errors.new_password || ''}
											/>
										</div>

										<div className="login-password-field">
											<InputField
												name="confirm_password"
												label="Confirm Password"
												required={true}
												placeholder="Enter here"
												value={formik.values.confirm_password}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={formik?.touched.confirm_password && formik.errors.confirm_password || ''}
											/>
										</div>
									</div>

									<div className="card-footer border-top">
										<Button
											name="Submit"
											type="submit"
											className={`btn btn-success  ${loading ? 'btn-loading' : ''}`}
											disabled={loading}
											label="Update Password"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div >
			</section >

		</>
	)
}

export default ChangePassword