import React, { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { CheckBox, InputField, SelectField } from 'views/stories'
import { _Object } from 'utils/common-functions'

const defaultBreaktime = {
	'start_time': '',
	'end_time': '',
	'label': ''
}

const defaultValues = [
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Mon',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Tue',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Wed',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Thu',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Fri',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Sat',
		'breaktimes': [defaultBreaktime]
	},
	{
		'start_time': '07:00',
		'end_time': '19:00',
		'work_day_name': 'Sun',
		'breaktimes': [defaultBreaktime]
	}
]

const CreateSchedules = () => {
	// const [loading, setLoading] = useState(false)
	const [timeOptions, setTimeOptions] = useState<string[]>([]);
	const [normalDays, setNormalDays] = useState<_Object[]>(defaultValues);

	const formik = useFormik({
		initialValues: normalDays,
		enableReinitialize: true,

		validationSchema: Yup.object({}),

		onSubmit: async () => {
			// setLoading(true)
		}
	})

	useEffect(() => {
		const generateTimeOptions = () => {
			const options = [];

			for (let hour = 0; hour < 24; hour++) {
				for (let minute = 0; minute < 60; minute += 30) {
					const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
					options.push(time);
				}
			}

			setTimeOptions(options);
		};

		generateTimeOptions();
	}, []);

	const addBreaktime = (index: number) => {
		const list = normalDays?.map((item: _Object, i: number) => {
			if (i == index) {
				return { ...item, breaktimes: [...formik.values[i].breaktimes, defaultBreaktime] }
			}

			return item
		})
		setNormalDays(list)
	}

	const removeBreaktime = (scheduleIdx: number, breaktimeIdx: number) => {
		delete formik.values[scheduleIdx].breaktimes[breaktimeIdx]

		const list = normalDays?.map((item: _Object, i: number) => {
			if (i == scheduleIdx) {
				return { ...item, breaktimes: item.breaktimes?.filter((item: _Object, i: number) => i !== breaktimeIdx) }
			}

			return item
		})
		setNormalDays(list)
	}

	return (
		<>
			<div className="schedules-section">
				<h3>Weekly hours</h3>
				<form className="card">
					{formik.values?.map((item: _Object, i: number) => (
						<div className="row" key={i}>
							<div className="col-8">
								<div className="col-1">
									<CheckBox
										options={[
											{ label: item.work_day_name, value: '' }
										]}
									/>
								</div>

								<div className="col-2">
									<SelectField
										name="start_time"
										label="Start time"
										value={{ value: item?.start_time }}
										options={timeOptions.map((item: string) => ({ label: item, value: item }))}
										onChange={(e: { [keys: string]: string }) => {
											formik.setFieldValue(`[${i}].start_time`, e?.value || '')
										}}
										onBlur={formik.handleBlur}
										// error={formik.touched?.schedules && formik.errors?.schedules && formik.touched?.schedules[i].start_time && formik.errors?.schedules[i]?.start_time || ''}
										isClearable
									/>

								</div>

								<div className="col-2">
									<SelectField
										name="end_time"
										label="End time"
										value={{ value: item.end_time }}
										options={timeOptions.map((item: string) => ({ label: item, value: item }))}
										onChange={(e: { [keys: string]: string }) => {
											formik.setFieldValue(`[${i}].end_time`, e?.value || '')
										}}
										onBlur={formik.handleBlur}
										// error={formik.touched?.schedules && formik.errors?.schedules && formik.touched?.schedules[i].start_time && formik.errors?.schedules[i]?.start_time || ''}
										isClearable
									/>
								</div>

								<div className="col-1">
									<button type="button" onClick={() => addBreaktime(i)} className="btn">Add Break</button>
								</div>
							</div>

							<div className="col-8">
								{item.breaktimes?.map((breaktime: _Object, idx: number) => (
									<div className="row" key={idx}>

										<div className="col-2">
											<p>breaktimes</p>
											<InputField
												type="text"
												name={`[${i}].breaktimes[${idx}].label`}
												label="Name"
												required={true}
												placeholder="Enter here"
												value={breaktime.label}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											// error={formik?.touched.customer_name && formik.errors.customer_name || ''}
											/>
										</div>
										<div className="col-5">

											<SelectField
												name={`[${i}].breaktimes[${idx}].start_time`}
												label="Start time"
												value={{ value: breaktime?.start_time }}
												options={timeOptions.map((item: string) => ({ label: item, value: item }))}
												onChange={(e: { [keys: string]: string }) => {
													formik.setFieldValue(`[${i}].breaktimes[${idx}].start_time`, e?.value || '')
												}}
												onBlur={formik.handleBlur}
												// error={formik.touched?.schedules && formik.errors?.schedules && formik.touched?.schedules[i].start_time && formik.errors?.schedules[i]?.start_time || ''}
												isClearable
											/>
											<SelectField
												name={`[${i}].breaktimes[${idx}].end_time`}
												label="End time"
												value={{ value: breaktime?.end_time }}
												options={timeOptions.map((item: string) => ({ label: item, value: item }))}
												onChange={(e: { [keys: string]: string }) => {
													formik.setFieldValue(`[${i}].breaktimes[${idx}].end_time`, e?.value || '')
												}}
												onBlur={formik.handleBlur}
												// error={formik.touched?.schedules && formik.errors?.schedules && formik.touched?.schedules[i].start_time && formik.errors?.schedules[i]?.start_time || ''}
												isClearable
											/>
										</div>
										<button type="button" className="btn" onClick={() => removeBreaktime(i, idx)}></button>

									</div>
								))}
							</div>

						</div>
					))}
				</form >
			</div>
		</>
	)
}
export default CreateSchedules;