import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

import store from './redux/store'

import App from './App'
import '../src/assets/scss/app.scss'
import '../src/assets/scss/main.scss'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<App />
		<ToastContainer />
	</Provider>
);

