import React, { useEffect, useState } from 'react';
import { _Object } from 'utils/types';

const OTPField = ({ args }: _Object) => {
	const [otp, setOTP] = useState<{ [key: string]: string }>({})

	useEffect(() => {
		(Object.values(otp).join('')).length === args.length && args.onChange(Object.values(otp).join(''))
	}, [(Object.values(otp).join('')).length, otp])

	useEffect(() => {
		args.resend && setOTP({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
	}, [args.resend])

	const handleChange = (event: _Object) => {
		setOTP((prev: { [key: string]: string }) => ({ ...prev, [event.target.name]: event.target.value }))
	}

	const inputFocus = (element: _Object) => {
		if (element.key === 'Delete' || element.key === 'Backspace') {
			const next = element.target.tabIndex - 2
			if (next > -1) {
				element.target.form.elements[next].focus()
			}
		}
		else {
			const next = element.target.tabIndex
			if (next < 6) {
				element.target.form.elements[element.key === 'v' ? 5 : next].focus()
			}
		}
	}

	const handlePaste = (event: any) => {
		const value = event.clipboardData.getData('text')
		value.split('').map((item: any, i: number) => {
			return (
				setOTP((pre: any) => ({
					...pre,
					[i]: item
				}))
			)
		})
	}
	return (
		<div className="security-code d-flex justify-content-center">
			{Array.from({ length: args.length }).map((_, i: number) => (
				<input
					key={i}
					type={args.secure ? 'password' : 'text'}
					name={`${i}`}
					tabIndex={i + 1}
					maxLength={1}
					value={otp[i]}
					className="form-control mx-1 text-center"
					style={{ width: '2.75rem', height: '2.5rem' }}
					onChange={handleChange}
					onKeyUp={inputFocus}
					onPaste={handlePaste}
				/>
			))}
		</div>
	)
}

export default OTPField