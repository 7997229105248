import React, { Suspense } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

const PublicLayout = () => {
	const location = useLocation();
	console.log('location', location);
	const { id } = useParams()
	console.log('id', id);

	return (
		<Suspense>
			{(location.pathname === '/thankyou' || id) ?
				<main className="container">
					<div className="row">
						<Outlet />
					</div>
				</main>
				:
				<main className="container-fluid">
					<div className="row">
						<div className="auth-form align-middles">
							<div className="col-lg-8 col-md-12">
								<div className="card">
									<div className="row">
										<Outlet />
									</div>

								</div>
							</div>
						</div>
					</div>
				</main>

			}
		</Suspense >
	)
}
export default PublicLayout