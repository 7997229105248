import React from 'react'

const Loader = () => {
	return (
		<div className="d-flex justify-content-center mx-3">
			<div className="spinner-border text-secondary" role="status">
			</div>
		</div>
	)
}
export default Loader