import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'utils/routes';

export interface AlertProps {
	message?: string
	client_id?: string
	email?: string
	error?: boolean,
}

const Alert = (props: AlertProps) => {
	const location = useLocation();

	const message = () => {
		if (props.message === 'Your account is not verified. Please verify your account.') {
			return (<span>{props.message} Click here <Link to={`${routes?.accountVerification}?client_id=${props.client_id}&email=${props.email || ''}`}>verification</Link> page</span>)
		} else if (location.pathname === '/register/account-verification' && props.error === false) {
			return <span>{props.message}, Get back to  <Link to="/login">login</Link> page</span>
		} else {
			return props.message
		}
	}

	return (
		<div className={`mt-3 alert alert-${props.error ? 'danger' : 'success'}`} role="alert">
			{message()}
		</div>
	);
}
export default Alert;
