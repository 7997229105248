import React from 'react';

export interface SwitchInputProps {
	label?: string;
	name: string;
	value?: boolean;
	onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
	error?: string;
}

const SwitchInput = (props: SwitchInputProps) => {
	return (
		<div className="form-switch">
			<div className="form-check form-switch">
				<input
					className="form-check-input"
					type="checkbox"
					role="switch"
					id={props.name}
					name={props.name}
					checked={props?.value || false}
					onChange={props.onChange}
				/>
				<label htmlFor={props.name} className="form-check-label">
					{props.label}
				</label>
			</div>

			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default SwitchInput;
