import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { InputField, SelectField, TextArea } from 'views/stories'

import { notificationService } from 'services'
import { SEOHeader } from 'views/components'
import { _Object, convertToTitleCase, templateVars } from 'utils/common-functions'
import { EditBlack } from '../assets/icons'

const EmailSetings = () => {
	const [loading, setLoading] = useState(false)
	const [emailSetings, setEmailSetings] = useState<any>([]);
	const [selectedSettingId, setSelectedSettingId] = useState<number>(-1);

	useEffect(() => {
		getNotification()
	}, [])

	const getNotification = () => {
		notificationService.getNotifications().then((data: _Object) => {
			setEmailSetings(data.items)
		})
	}

	const formik: _Object = useFormik({
		initialValues: emailSetings,
		enableReinitialize: true,
		onSubmit: async (values) => {
			setLoading(true)

			const data = {
				'content': values[selectedSettingId].content,
				'subject': values[selectedSettingId].subject,
				'from_name': values[selectedSettingId].from_name,
				'from_email': values[selectedSettingId].from_email,
				'status': values[selectedSettingId].status// active, inactive
			}

			notificationService.updateNotification(values[selectedSettingId].id, data).then((res: _Object) => {
				console.log('datadatadata', res);
				getNotification()
				setLoading(false)
			})
		}
	})

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(`[${text}]`)
			.then(() => {
				console.log('Text copied to clipboard:', text);
				// Optionally, you can provide feedback to the user that the text was copied successfully
			})
			.catch((error) => {
				console.error('Unable to copy text to clipboard:', error);
				// Handle any errors here
			});
	};

	return (
		<div className="email-notifications">
			<div className="row">
				<SEOHeader title="Email notifications settings" />

				<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
					<div className="card">
						<div className="card-header">
							<h5 className="mb-0">Email notifications settings</h5>
						</div>
						<div className="card-body">

							<div className="notifications-settings table-responsive">

								<table className="table table-bordered table-advance">
									<thead>
										<tr>
											<td>Notification</td>
											<td className="text-center">Email notification for service provider</td>
											<td className="text-center">Admin</td>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td>Booking confirmation</td>

											<td className="justify-content-center d-flex">
												<div className="form-group">
													<div className="form-check justify-content-center d-flex">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															id="edit-checkbox" />
													</div>
													<a href={'/app/settings/email#'} className="btn p-0 border-0">
														<img src={EditBlack} alt="Edit" width={18} height={20} className="edit-button" />
														Edit template</a>
												</div>
											</td>

											<td className="justify-content-center text-center">
												<div className="form-group">
													<div className="form-check justify-content-center d-flex">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															id="edit-checkbox" />
													</div>
													<a href={'/app/settings/email#'} className="btn p-0 border-0">
														<img src={EditBlack} alt="Edit" width={18} height={20} className="edit-button" />
														Edit template</a>
												</div>
											</td>
										</tr>
									</tbody>

								</table>

								<div className="accordion" id="services">
									{formik.values?.map((service: _Object, i: number) => (
										<div className="accordion-item" id={service.name} key={i}>
											<form onSubmit={formik.handleSubmit}>
												<h2 className="accordion-header">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target={`#service-${i}`}>
														{convertToTitleCase(service.name)}
													</button>
												</h2>
												<div
													id={`service-${i}`}
													className="accordion-collapse collapse"
													data-bs-parent="#services">
													<div className="accordion-body">

														<div className="row">
															<div className="col-6">
																<InputField
																	type="text"
																	name={`[${i}].from_name`}
																	label="From name"
																	placeholder="Enter here"
																	value={formik.values[i].from_name}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	error={formik?.touched.from_name && formik.errors.from_name || ''}
																/>
															</div>

															<div className="col-6">
																<InputField
																	type="text"
																	name={`[${i}].from_email`}
																	label="From email"
																	placeholder="Enter here"
																	value={formik.values[i].from_email}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	error={formik?.touched.from_email && formik.errors.from_email || ''}
																/>
															</div>
															<div className="col-12">
																<InputField
																	type="text"
																	name={`[${i}].subject`}
																	label="Subject"
																	placeholder="Enter here"
																	value={formik.values[i].subject}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	error={formik?.touched.subject && formik.errors.subject || ''}
																/>
															</div>
															<div className="col-12">
																<TextArea
																	label="Content"
																	name={`[${i}].content`}
																	value={formik.values[i].content}
																	rows={4}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	disabled={false}
																	placeholder="Enter here"
																	error={formik?.touched.content && formik.errors.content || ''}
																/>
															</div>
														</div>
													</div>
													<div className="accordion-footer text-end px-3 pb-3">
														<button name="Send" type="submit"
															onClick={() => setSelectedSettingId(i)}
															className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
															disabled={loading}>
															<span className="btn-text">Save</span>
														</button>
													</div>
												</div>
											</form>
										</div>))}
								</div>

								<div className="accordion" id="settings-email">

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#settings-email">
												Settings for e-mail and SMS notifications
											</button>
										</h2>

										<div id="settings-email"
											className="accordion-collapse collapse"
											data-bs-parent="#settings-parent">
											<form className="accordion-body">
												<div className="row field-container">
													<div className="col-6 my-auto">
														<label>Default phone (country code and number)</label>
													</div>

													<div className="col-6">
														<SelectField
														/>
													</div>
												</div>

												<div className="row field-container">
													<div className="col-6 my-auto">
														<label>Attach iCal to e-mails </label>
													</div>

													<div className="col-6">
														<div className="form-group">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	value=""
																	id="check-box" />
															</div>

														</div>
													</div>
												</div>

												<div className="row field-container">
													<div className="col-6 my-auto">
														<label>Send feedback request X days after booking </label>
													</div>

													<div className="col-6">
														<SelectField
														/>
													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>Minimum number of days between two feedback requests	</label>
													</div>

													<div className="col-6">
														<SelectField
														/>
													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>Send Thank you message to client for reviewing your service</label>
													</div>

													<div className="col-6">
														<div className="form-group">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	value=""
																	id="check-box" />
															</div>

														</div>

													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>Enable forced Base 64 encoding email headers </label>
													</div>

													<div className="col-6">
														<div className="form-group">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	value=""
																	id="check-box" />
															</div>

														</div>

													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>Enable Push notifications</label>
													</div>

													<div className="col-6">
														<div className="form-group">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	value=""
																	id="check-box" />
															</div>

														</div>

													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>Mandatory registration fields </label>
													</div>

													<div className="col-6">
														<SelectField />
													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>List of cancelled recurring/group bookings by SMS</label>
													</div>

													<div className="col-6">
														<InputField
														/>

													</div>
												</div>

												<div className="row field-container">

													<div className="col-6 my-auto">
														<label>List of cancelled recurring/group bookings by e-mail</label>
													</div>

													<div className="col-6">
														<InputField />

													</div>
												</div>
											</form>
										</div>
									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed"
												type="button" data-bs-toggle="collapse"
												data-bs-target="#client-target">
												Client s booking confirmation template
											</button>
										</h2>

										<div id="client-target"
											className="accordion-collapse collapse"
											data-bs-parent="#client-parent">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2"
																	id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea
																	className="form-control"
																	id="email-templates"
																	rows={9}>

																</textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#new-booking-target">
												New booking confirmation for service providers
											</button>
										</h2>

										<div id="new-booking-target"
											className="accordion-collapse collapse"
											data-bs-parent="#new-bookings-parent">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="email-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#remider-providers">
												Remider to providers
											</button>
										</h2>

										<div id="remider-providers"
											className="accordion-collapse collapse"
											data-bs-parent="#remider-provider-parent">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField />
																<textarea className="form-control mt-2" id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="email-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#cancellation-target">
												Cancellation confirmation
												template to clients
											</button>
										</h2>

										<div id="cancellation-target"
											className="accordion-collapse collapse"
											data-bs-parent="#cancellation-parent">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="confirmation-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="confirmation-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#cancellation-providers">
												Cancellation confirmation
												template for providers
											</button>
										</h2>

										<div id="cancellation-providers"
											className="accordion-collapse collapse"
											data-bs-parent="#cancellation-providers">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="email-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#cancellation-change">
												Cancellation of a change in booking-template
												for clients
											</button>
										</h2>

										<div id="cancellation-change"
											className="accordion-collapse collapse"
											data-bs-parent="#review-notification1">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label htmlFor="email-template" className="form-label">Email Template:</label>

																<textarea className="form-control" id="email-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TemplateFeedback" aria-expanded="false">
												Template for feedback request form clinets
											</button>
										</h2>

										<div id="TemplateFeedback" className="accordion-collapse collapse" data-bs-parent="#TemplateFeedback">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label htmlFor="exampleFormControlTextarea1" className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="exampleFormControlTextarea1" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label htmlFor="exampleFormControlTextarea1" className="form-label">Email Template:</label>

																<textarea className="form-control" id="exampleFormControlTextarea1" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#review-notification">
												New review notification to company
											</button>
										</h2>

										<div id="review-notification"
											className="accordion-collapse collapse"
											data-bs-parent="#review-notification">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="email-template" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="email-template" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#notification-client"
											>
												New review notification to company
											</button>
										</h2>

										<div id="notification-client"
											className="accordion-collapse collapse"
											data-bs-parent="#notification-client">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="review-area" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="review-area" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#login-notification-target">
												New user login notification
											</button>
										</h2>

										<div id="login-notification-target"
											className="accordion-collapse collapse"
											data-bs-parent="#login-notification-parent">
											<div className="accordion-body">
												<form>
													<div className="row">
														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>
																<InputField

																/>
																<textarea className="form-control mt-2" id="notification-area" rows={6}></textarea>
															</div>
														</div>

														<div className="col-6">
															<div className="mb-3">
																<label className="form-label">Email Template:</label>

																<textarea className="form-control" id="notification-area" rows={9}></textarea>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

									</div>

								</div>

							</div>

						</div >
					</div >

				</div >

				<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
					<div className="template-vars">

						<h5>Template vars</h5>
						<p>
							For all templates you can also use the following tags:
						</p>

						{templateVars.map((item, i) => (

							<div className="d-flex align-items-center mb-3" key={i}>

								<div className="flex-shrink-0">
									<button className="btn btn-outline-success" onClick={() => copyToClipboard(item.label)}>
										{item.label}
									</button>
								</div>
								<div className="flex-grow-1 ms-2">
									&nbsp; - {item.description}
								</div>

							</div>
						))}
					</div>
				</div>
			</div >
		</div >
	);
};
export default EmailSetings;
