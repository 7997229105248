import React from 'react'
import NormalDays from './normal-days'
import SpecialDays from './special-days'
import { SEOHeader } from 'views/components'

const Schedules = () => {
	return (
		<>
			<SEOHeader title="Schedules" />
			<div className="schedules-content">
				<ul className="nav nav-pills" id="myTab" role="schedules">
					<li className="nav-item"
						role="presentation">
						<button className="nav-link active px-0"
							id="schedules-tab"
							data-bs-toggle="tab"
							data-bs-target="#schedules"
							type="button"
							role="schedules"
						>
							Schedules
						</button>
					</li>

					<li className="nav-item" role="special-days">
						<button
							className="nav-link"
							id="special-days-tab"
							data-bs-toggle="tab"
							data-bs-target="#special-days"
							type="button"
							role="special-days"
						>
							Special days
						</button>
					</li>
				</ul>

				<div className="tab-content" id="shedules-tabs">
					<div className="tab-pane fade show active" id="schedules" role="tabpanel" aria-labelledby="schedules-tab"><NormalDays /></div>
					<div className="tab-pane fade" id="special-days" role="tabpanel" aria-labelledby="special-days-tab"><SpecialDays /></div>
				</div>
			</div>
		</>
	)
}
export default Schedules;