import React, { useState, ChangeEvent } from 'react';
import { EyeClose, EyeOpen } from './icons';

interface PasswordFieldProps {
	label?: string;
	name?: string;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	className?: string;
	placeholder?: string;
	error?: string;
	[key: string]: any;
}

const PasswordField: React.FC<PasswordFieldProps> = (props: PasswordFieldProps) => {
	const [eyeOn, setEyeOn] = useState(false);
	const [fieldType, setFieldType] = useState<'text' | 'password'>('password');

	const toggleEyeOn = () => {
		if (eyeOn === false) {
			setEyeOn(true);
			setFieldType('text');
		} else {
			setEyeOn(false);
			setFieldType('password');
		}
	};

	return (
		<div className={`form-group mb-3 ${props.className} ${props.error && 'invalid'}`}>
			{props.label && (
				<label className="form-label">
					{props.label} {props.required && <span className="text-danger">*</span>}
				</label>
			)}

			<input
				type={fieldType}
				autoComplete="off"
				name={props.name}
				placeholder={props.placeholder}
				value={props.value ?? ''}
				onChange={props.onChange}
				className={'form-control'}
				{...props}
			/>
			<button
				type="button"
				className="btn show-password border-0"
				onClick={() => toggleEyeOn()}
			>
				{eyeOn ? <img src={EyeClose} alt="Eye Close" width={20} height={16} /> :
					<img src={EyeOpen} alt="Eye Open" width={20} height={16} />}
			</button>
			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default PasswordField;
