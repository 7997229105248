import React, { useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { InputField, SelectField, TextArea } from 'views/stories'

import { _Object, closeModal } from 'utils/common-functions'
import { syncTimeService } from 'services'

const AddEditService = ({ getServices, selectedService }: _Object) => {
	const [loading, setLoading] = useState<boolean>(false)

	const formik: any = useFormik({
		initialValues: selectedService.title ? selectedService : {
			title: '',
			status: '',
			description: '',
			duration: null,
			buffer: null
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			title: Yup.string().label('Title').required(),
			status: Yup.string().label('Status').required(),
			duration: Yup.string().label('Duration').required(),
			buffer: Yup.string().label('Buffer').required()
			// description: Yup.string().label('Description').required()
		}),

		onSubmit: async (values) => {
			setLoading(true)
			if (selectedService.title) {
				syncTimeService.updateService(selectedService.id, {
					description: values.description,
					status: values.status,
					title: values.title,
					buffer: values.buffer,
					duration: values.duration
				}).then((data: _Object) => {
					if (!data.error) {
						getServices()
						formik.resetForm()
						closeModal('NewService')
					}
					setLoading(false)
				})
			} else {
				syncTimeService.createService(values).then((data: _Object) => {
					if (!data.error) {
						getServices()
						formik.resetForm()
						closeModal('NewService')
					}
					setLoading(false)
				})
			}
		}
	})

	return (
		<div className="modal fade" id="NewService" aria-labelledby="NewServiceLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="mb-0">New Service</h5>
						<button type="button" className="btn-close"
							onClick={() => formik.resetForm()}
							data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form onSubmit={formik.handleSubmit}>
						<div className="modal-body">
							<div className="row">
								<div className="col-6">
									<InputField
										type="text"
										name="title"
										label="Title"
										required={true}
										placeholder="Enter here"
										value={formik.values.title}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik?.touched.title && formik.errors.title || ''}
									/>
								</div>
								<div className="col-6">
									<SelectField
										name="status"
										label="Status"
										required={true}
										value={{ value: formik.values.status }}
										options={[
											{ label: 'Active', value: 'active' },
											{ label: 'In Active', value: 'inactive' },
											{ label: 'Draft', value: 'draft' }
										]}
										onChange={(e: _Object) => {
											formik.setFieldValue('status', e?.value || '')
										}}
										onBlur={formik.handleBlur}
										error={!formik?.values.status && formik.errors.status && formik.errors.status}
										isClearable
									/>

								</div>
								<div className="col-12">
									<TextArea
										label="Description"
										name="description"
										value={formik.values.description}
										rows={4}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										disabled={false}
										placeholder="Enter here"
										// required={true}
										error={formik?.touched.description && formik.errors.description || ''}
									/>
								</div>
								<div className="col-6">
									<div className={'form-group mb-3 '}>
										<label className="form-label">Duration <span className="text-danger">*</span></label>
										<SelectField
											name="duration"
											value={{ value: formik.values.duration || '' }}
											options={[15, 30, 45, 60, 75, 90].map((item: number) => ({ label: `${item} min`, value: item }))}
											onChange={(e) => formik.setFieldValue('duration', Number(e?.value || 0))}
											onBlur={formik.handleBlur}
											isClearable
											error={!formik?.values.duration && formik.errors.duration && formik.errors.duration}
										/>
									</div>

								</div>
								<div className="col-6">
									<div className={'form-group mb-3 '}>
										<label className="form-label">Buffer <span className="text-danger">*</span></label>
										<SelectField
											name="buffer"
											value={{ value: formik.values.buffer || '' }}
											options={[5, 10, 15, 20, 25, 30].map((item: number) => ({ label: `${item} min`, value: item }))}
											onChange={(e) => formik.setFieldValue('buffer', Number(e?.value || 0))}
											onBlur={formik.handleBlur}
											isClearable
											error={!formik?.values.buffer && formik.errors.buffer && formik.errors.buffer}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="submit" className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
								disabled={loading}
							>
								{selectedService.id ? 'Update' : 'Submit'}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
export default AddEditService