import React, { useState } from 'react'

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { SEOHeader } from 'views/components'
import '../style.scss'

import { HandMobile, Massage } from '../images'
import OTPField from 'views/components/otp'
import { Logo } from 'assets/images';
import { authService } from 'services';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'views/stories';
import Alert from 'views/components/alerts';
// import { authService } from 'services'
// import { _Object } from 'utils/common-functions'

const AccountVerification = () => {
	// const query = new URLSearchParams()
	// const token = '3fea8a1d-11c4-4fd8-ac8c-ca7ac3752bc9';
	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false)
	const [resend, setResend] = useState(false)
	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})
	const queryParams = new URLSearchParams(location.search);

	const formik = useFormik({
		initialValues: {
			otp: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object(),

		onSubmit: async (values) => {
			setError({})
			const value = {
				'otp': values.otp,
				'client_id': queryParams.get('client_id') || ''
			}
			setLoading(true);
			authService.accountVerification(value).then((data) => {
				setError(data)
				setLoading(false);
				if (data?.error === false) {
					navigate('/login');
				}
			})
		}
	});

	const resendOTP = () => {
		if (queryParams.get('client_id')) {
			setError({})
			setResend(true)
			authService.resendAccountVerification({ client_id: queryParams.get('client_id') || '' })
				.then(() => { setResend(false) });
		}
	}

	return (
		<>
			<SEOHeader title="Account-Verification" />
			<div className="col-lg-6 col-md-12">
				<div className="verification-form">
					<div className="login-form-col">
						<div className="brand-logo">
							<img src={Logo} alt="Logo" />
							<h4>Two-Step Verification &nbsp;
								<img src={Massage} className="m-0" alt="Two-Step Verification" /></h4>
							<p>Enter the verification code we sent to your email&nbsp;<span>{queryParams.get('email') || ''}</span></p>
						</div>

						<form className="row" onSubmit={formik.handleSubmit}>
							<OTPField
								args={{
									length: 6,
									secure: false,
									onChange: (otp: string) => otp && formik.setFieldValue('otp', otp),
									resend: resend
								}}
							/>
							<Button name="Sign In" type="submit" className={`btn btn-success w-100 mt-3 ${loading ? 'btn-loading' : ''} `} label="VERIFY"
								disabled={resend || loading} />
							{/* <button
								type="submit"
								className="btn btn-success w-100 mt-3"
								disabled={resend || loading}
							>
								{loading ? 'Loading...' : 'VERIFY'}
							</button> */}
						</form>

						<p className="my-3 get-the-code">
							Didn&apos;t get the code?
							<Button className="btn btn-link p-0 ms-1 me-2" type="button" onClick={resendOTP} disabled={resend} loading={resend}>
								Resend
							</Button>
							{resend && <div className="spinner-border text-secondary" role="status">
							</div>}
						</p>
					</div>
					{error.message && <Alert {...error} />}
				</div>
			</div>

			<div className="col-lg-6 col-md-12">
				<div className="hand-moblie-col">
					<img src={HandMobile} alt="Hand Mobile" />
				</div>
			</div>

		</>
	)
}

export default AccountVerification