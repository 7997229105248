import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import DatePicker from 'react-date-picker'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PlusBlack } from '../assets/icons';

import { Button } from 'views/stories'
import { scheduleService } from 'services/schedules.service'
import { _Object } from 'utils/common-functions'
import Loader from 'views/components/loader'

interface Schedule {
	work_day_name: string;
}

const defaultValues: Schedule[] = [];

const SpecialDays = () => {
	// const [value, onChange] = useState<any>(new Date());
	const [loading, setLoading] = useState(false)
	const [listLoading, setListLoading] = useState(false);
	const [schedules, setSchedules] = useState<Schedule[]>(defaultValues);
	const { loggedInUser } = useSelector((state: _Object) => state.session)

	const validationSchema = Yup.array().of(
		Yup.object().shape({
			work_day_name: Yup.string().required('Work day date is required')
		})
	);

	const formik = useFormik<Schedule[]>({
		initialValues: schedules,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values) => {
			setLoading(true);
			try {
				await scheduleService.createSchedules({
					schedules: values,
					entity_id: loggedInUser.id,
					is_special_day: true
				});
			} catch (error) {
				console.error('Error saving schedules:', error);
			} finally {
				setLoading(false);
			}
		}
	});

	useEffect(() => {
		if (loggedInUser.id) {
			setListLoading(true)
			scheduleService.getSchedules({ entity_id: loggedInUser.id, is_special_day: true })
				.then((data) => {
					console.log(data);

					setSchedules(data.items)
					// formik.setValues(updatedSchedules);
					setListLoading(false)
				});
		}
	}, [loggedInUser.id]);

	// const handleCheckboxChange = (e: any, value: string, index: number) => {
	// 	if (e.target.checked === true) {
	// 		setState(prevValues => [...prevValues, value]);
	// 	} else {
	// 		setState(prevValues => prevValues.filter(item => item !== value));
	// 		formik.setFieldValue(`schedules[${index}]`, {
	// 			start_time: '',
	// 			end_time: '',
	// 			work_day_name: value,
	// 			breaktimes: []
	// 		});
	// 	}
	// };
	// console.log('openCalendar', openCalendar);

	const addBreaktime = () => {
		const newSchedules: any = [...formik.values];
		newSchedules.push({ work_day_name: '' });
		setSchedules(newSchedules);
	};

	const removeBreaktime = (scheduleIdx: number) => {
		const newSchedules = [...formik.values];
		newSchedules.splice(scheduleIdx, 1);
		setSchedules(newSchedules);
	};

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		formik.handleSubmit();
	};

	return (
		<>
			<div className="normal-day-row">
				<div className="card">
					<div className="row">
						<div className="col-8">
							<div className="space-between my-3">
								<div className="space-between">
									<h5 className="my-auto">Special days</h5>
									{listLoading && <Loader />}
								</div>
								<div className="space-between">
									<Button className="btn btn-success" type="submit" onClick={handleSubmit} disabled={loading}>
										{loading ? 'Saving...' : 'Save'}
									</Button>
									<Button className="btn weekly-hours-button ms-3" type="button" onClick={addBreaktime} disabled={loading}>
										<img src={PlusBlack} alt="Add Break" width={13} height={13} />&nbsp; Add
									</Button>
								</div>
							</div>
						</div>
					</div>
					<form onSubmit={formik.handleSubmit}>
						{formik.values?.map((item: _Object, i: number) => (
							<div className="row mb-3" key={i}>
								<div className="col-8">
									<div className="row weekly-hours-row">
										<div className="col-6 my-auto">
											<DatePicker
												key={i}
												onChange={date => formik.setFieldValue(`[${i}].work_day_name`, date)}
												name={`[${i}].work_day_name`}
												value={item.work_day_name}
											/>
											{formik.errors[i]?.work_day_name && formik.touched[i]?.work_day_name && (
												<div className="invalid-feedback text-danger d-block mt-1">{formik.errors[i]?.work_day_name}</div>
											)}
										</div>
										<div className="col-6 my-auto text-end">
											<Button className="btn danger-button" type="button" onClick={() => removeBreaktime(i)} disabled={loading}>
												Delete
											</Button>
										</div>
									</div>
								</div>
							</div>
						))}
					</form>
				</div>
			</div>
		</>
	)
}
export default SpecialDays;