import { generateQueryParams } from 'utils/common-functions';

import CommonService from './common.service';

class ScheduleService extends CommonService {
	async getSchedules(params?: { [key: string]: boolean | string }) {
		return await this.get(`schedules/?${generateQueryParams(params)}`)
	}

	async createSchedules(parmas: { [key: string]: boolean | object | string }) {
		return await this.post('schedules', parmas)
	}
}
export const scheduleService = new ScheduleService();

