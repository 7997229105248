import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, InputField, SelectField } from 'views/stories';
import { scheduleService } from 'services';
import { Close, PlusBlack } from '../assets/icons';
import SwitchInput from 'views/stories/Form-controls/Switch';
import { _Object, timeOptions } from 'utils/common-functions';
import Loader from 'views/components/loader';

interface Breaktime {
	start_time: string;
	end_time: string;
	label: string;
}

interface Schedule {
	is_working: boolean;
	start_time: string;
	end_time: string;
	work_day_name: string;
	breaktime: Breaktime[];
}

const defaultValues: Schedule[] = [
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Monday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Tuesday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Wednesday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Thursday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Friday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Saturday', breaktime: [] },
	{ is_working: false, start_time: '', end_time: '', work_day_name: 'Sunday', breaktime: [] }
];

const NormalDays = () => {
	const [loading, setLoading] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	// const [timeOption, setTimeOption] = useState(timeOptions());
	// console.log(setTimeOption)
	const timeOption = timeOptions();
	const [schedules, setSchedules] = useState<Schedule[]>(defaultValues);
	const { loggedInUser } = useSelector((state: _Object) => state.session);

	const validationSchema = Yup.array().of(
		Yup.object().shape({
			is_working: Yup.boolean(),
			start_time: Yup.string().when('is_working', ([is_working]) => {
				return is_working ? Yup.string().required('Start time is required') : Yup.string();
			}),
			end_time: Yup.string().when('is_working', ([is_working]) => {
				return is_working ? Yup.string().required('End time is required') : Yup.string();
			}),
			breaktime: Yup.array().of(
				Yup.object().shape({
					start_time: Yup.string().when('is_working', (is_working, schema) => {
						return is_working ? schema.required('Break start time is required') : schema;
					}),
					end_time: Yup.string().when('is_working', (is_working, schema) => {
						return is_working ? schema.required('Break end time is required') : schema;
					}),
					label: Yup.string().when('is_working', (is_working, schema) => {
						return is_working ? schema.required('Break label is required') : schema;
					})
				})
			)
		})
	);

	const formik = useFormik<Schedule[]>({
		initialValues: schedules,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values) => {
			const activeSchedules = values.filter((item: any) => item.is_working === true);
			setLoading(true);
			try {
				await scheduleService.createSchedules({
					schedules: activeSchedules,
					entity_id: loggedInUser.id,
					is_special_day: false
				});
			} catch (error) {
				console.error('Error saving schedules:', error);
			} finally {
				setLoading(false);
			}
		}
	});

	useEffect(() => {
		if (loggedInUser.id) {
			setListLoading(true)
			scheduleService.getSchedules({ entity_id: loggedInUser.id, is_special_day: false })
				.then((data) => {
					const updatedSchedules = schedules.map((item) => {
						const day = data.items.find((key: any) => key.work_day_name === item.work_day_name);
						return day ? { ...day, breaktime: day.breaktime || [], is_working: true } : item;
					});
					setSchedules(updatedSchedules)
					// formik.setValues(updatedSchedules);
					setListLoading(false)
				});
		}
	}, [loggedInUser.id]);

	const addBreaktime = (index: number) => {
		const newSchedules: any = [...formik.values];
		newSchedules[index].breaktime.push({ start_time: '', end_time: '', label: '' });
		setSchedules(newSchedules);
		formik.setFieldValue(`[${index}].breaktime`, newSchedules[index].breaktime);
	};

	const removeBreaktime = (scheduleIdx: number, breaktimeIdx: number) => {
		const newSchedules = [...formik.values];
		newSchedules[scheduleIdx].breaktime.splice(breaktimeIdx, 1);
		setSchedules(newSchedules);
		formik.setFieldValue(`[${scheduleIdx}].breaktime`, newSchedules[scheduleIdx].breaktime);
	};

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		formik.handleSubmit();
	};

	return (
		<>
			<div className="normal-day-row">
				<div className="card">
					<div className="row">
						<div className="col-10">
							<div className="space-between button-height">
								<div className="space-between align-items-center">
									<h5 className="my-auto">Weekly hours</h5>
									{listLoading && <Loader />}
								</div>
								{formik.dirty && <Button className="btn btn-success" type="submit" onClick={handleSubmit} disabled={loading}>
									{loading ? 'Saving...' : 'Save'}
								</Button>}
							</div>
						</div>
					</div>

					<form className="card-body ps-0" onSubmit={formik.handleSubmit}>
						{formik.values.map((item, i) => {
							return (
								<div className="row mb-3" key={i}>
									<div className="col-10">
										<div className="row weekly-hours-row">
											<div className="col my-auto">
												<SwitchInput
													name={item.work_day_name.toLowerCase()}
													value={item.is_working}
													label={item.work_day_name}
													onChange={(e) => formik.setFieldValue(`[${i}].is_working`, e.target.checked)}
												/>
											</div>

											{item.is_working ? (
												<>
													<div className="col-3 my-auto">
														<SelectField
															name={`[${i}].start_time`}
															value={{ value: item.start_time || '' }}
															options={timeOption}
															onChange={(e) => formik.setFieldValue(`[${i}].start_time`, e?.value)}
															onBlur={formik.handleBlur}
															placeholder={'Start time'}
															isClearable
															error={formik.errors[i]?.start_time && formik.touched[i]?.start_time && formik.errors[i]?.start_time || ''}
														/>
													</div>
													<div className="col-3 my-auto">
														<SelectField
															name={`[${i}].end_time`}
															value={{ value: item.end_time || '' }}
															options={timeOption}
															onChange={(e) => formik.setFieldValue(`[${i}].end_time`, e?.value)}
															onBlur={formik.handleBlur}
															placeholder={'End time'}
															isClearable
															error={formik.errors[i]?.end_time && formik.touched[i]?.end_time && formik.errors[i]?.end_time || ''}
														/>
													</div>

													<div className="col my-auto text-end">
														<button type="button" onClick={() => addBreaktime(i)} className="btn weekly-hours-button">
															<img src={PlusBlack} alt="Add Break" width={13} height={13} />&nbsp; Add Break
														</button>
													</div>

													<div className="col-12">
														{item.breaktime.map((breaktime: { label: string, start_time: string, end_time: string }, idx) => (
															<div className="row mt-3 breaktimes-row" key={idx}>
																<div className="col-3 my-auto">
																	<InputField
																		name={`[${i}].breaktime[${idx}].label`}
																		required={true}
																		placeholder={'Break Label'}
																		value={breaktime.label}
																		onChange={(e) => formik.setFieldValue(`[${i}].breaktime[${idx}].label`, e.target.value)}
																		onBlur={formik.handleBlur}
																		error={(formik.errors[i]?.breaktime?.[idx] as Breaktime)?.label && formik.touched[i]?.breaktime?.[idx]?.label && (formik.errors[i]?.breaktime?.[idx] as Breaktime)?.label || ''}
																	/>
																</div>

																<div className="col-3 my-auto">
																	<SelectField
																		name={`[${i}].breaktime[${idx}].start_time`}
																		value={{ value: breaktime.start_time || '' }}
																		options={timeOption}
																		onChange={(e) => formik.setFieldValue(`[${i}].breaktime[${idx}].start_time`, e.value)}
																		onBlur={formik.handleBlur}
																		placeholder={'Start time'}
																		isClearable
																		error={(formik.errors[i]?.breaktime?.[idx] as Breaktime)?.start_time && formik.touched[i]?.breaktime?.[idx]?.start_time && (formik.errors[i]?.breaktime?.[idx] as Breaktime)?.start_time || ''}
																	/>
																</div>

																<div className="col-3 my-auto">
																	<SelectField
																		name={`[${i}].breaktime[${idx}].end_time`}
																		value={{ value: breaktime.end_time || '' }}
																		options={timeOption}
																		onChange={(e) => formik.setFieldValue(`[${i}].breaktime[${idx}].end_time`, e.value)}
																		onBlur={formik.handleBlur}
																		placeholder={'End time'}
																		isClearable
																		error={(formik.errors[i]?.breaktime?.[idx] as Breaktime)?.end_time && formik.touched[i]?.breaktime?.[idx]?.end_time && (formik.errors[i]?.breaktime?.[idx] as Breaktime)?.end_time || ''}
																	/>
																</div>
																<div className="col my-auto text-end">
																	<button type="button" className="btn" onClick={() => removeBreaktime(i, idx)}>
																		<img src={Close} alt="Remove Break" width={10} height={10} />
																	</button>
																</div>
															</div>
														))}
													</div>
												</>
											) : (
												<div className="col-8 my-auto">Day Off</div>
											)}
										</div>
									</div>
								</div>
							)
						})}
					</form>
				</div>
			</div>
		</>
	);
};

export default NormalDays;
