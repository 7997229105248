import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { _Object, routes } from 'utils/common-functions'
import { PrivateRoutes, PublicRoutes } from 'routes'
import { PrivateLayout, PublicLayout } from 'views/layouts'

const App = () => {
	const { isUserLoggedIn } = useSelector((state: { [key: string]: _Object }) => state.session)

	return (
		<BrowserRouter>
			<Suspense>
				<Routes>
					<Route path="/app" element={isUserLoggedIn ? <PrivateLayout /> : <Navigate to="/login" />}>
						<Route index element={<Navigate to={`${routes.dashboard}`} />} />

						{PrivateRoutes.map((route, i) => {
							return (
								<Route
									key={i}
									path={route.path}
									element={<route.component />}
								/>
							);
						})}

						<Route
							path="/app/*"
							element={<Navigate to={`${routes.dashboard}`} />}
						/>
					</Route>

					<Route path="/" element={<PublicLayout />}>
						<Route index element={<Navigate to={`${routes.login}`} />} />

						{PublicRoutes.map((route, i) => {
							return (
								<Route
									key={i}
									path={route.path}
									element={<route.component />}
								/>
							);
						})}

						<Route path="/*" element={<Navigate to={`${routes.users}`} />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
