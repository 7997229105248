import { routes } from 'utils/routes';
import { Thankyou } from 'views/pages';
import { ForgotPassword, Login, Register, ResetPassword } from 'views/pages/auth';
import AccountVerification from 'views/pages/auth/register/account-verification';
import Users from 'views/pages/users';

const PublicRoutes = [
	{ path: `${routes.login}`, exact: true, name: 'Login', component: Login },
	{ path: `${routes.resetPassword}`, exact: true, name: 'Reset Password', component: ResetPassword },
	{ path: `${routes.forgotPassword}`, exact: true, name: 'Forgot Password', component: ForgotPassword },
	{ path: `${routes.register}`, exact: true, name: 'Register', component: Register },
	{ path: `${routes.accountVerification}`, exact: true, name: 'Register', component: AccountVerification },
	{ path: `${routes.users}`, exact: true, name: 'Users', component: Users },
	{ path: `${routes.thankyou}`, exact: true, name: 'Thankyou', component: Thankyou }
];

export default PublicRoutes;
