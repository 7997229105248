import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { InputField, PhoneNumberField, PasswordField, Button } from 'views/stories';
import { authService } from 'services';
import { Logo } from 'assets/images';
import { SEOHeader } from 'views/components';
import { routes } from 'utils/common-functions';
import '../style.scss';
import { HandMobile } from '../images';
import Alert from 'views/components/alerts';

const Register = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})

	const formik = useFormik({
		initialValues: {
			first_name: '',
			last_name: '',
			phone_number: '',
			email: '',
			password: '',
			confirm_password: ''
		},
		enableReinitialize: true,

		validationSchema: Yup.object({
			first_name: Yup.string().label('First Name').required('First name is required'),
			last_name: Yup.string().label('Last Name').required('Last name is required'),
			phone_number: Yup.string().label('Phone Number').required('Phone number is required'),
			email: Yup.string().label('Email').required('Email is required').email('Invalid email address'),
			password: Yup
				.string()
				.required('Password is required')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
					'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character'
				),
			confirm_password: Yup
				.string()
				.oneOf([Yup.ref('password')], 'Passwords must match')
				.required('Confirm password is required')
		}),

		onSubmit: async (values) => {
			setError({})
			const convertedFllName = {
				name: formik.values.first_name + ' ' + formik.values.last_name,
				email: values.email,
				password: values.password,
				phone_number1: values.phone_number
			};

			setLoading(true);
			authService.register(convertedFllName)
				.then((data) => {
					setError(data)
					if (data.error === false || data.client_id) {
						navigate(`${routes?.accountVerification}?client_id=${data.client_id}&email=${values.email}`);
					}
					setLoading(false);
				});
		}
	});

	return (
		<>
			<SEOHeader title="Register" />
			<div className="col-lg-6 col-md-12">
				<div className="login-form-col">
					<div className="brand-logo">
						<img src={Logo} alt="Logo" />
						<h4></h4>
						<p>Fill out the form to set up your account and start managing appointments.</p>
					</div>

					<form className="row" onSubmit={formik.handleSubmit}>
						<InputField
							type="text"
							className="col-lg-6 col-md-6 col-12"
							name="first_name"
							label="First name"
							required={true}
							value={formik.values.first_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.first_name && formik.errors.first_name || ''}
						/>
						<InputField
							type="text"
							className="col-lg-6 col-md-6 col-12"
							name="last_name"
							label="Last name"
							required={true}
							value={formik.values.last_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.last_name && formik.errors.last_name || ''}
						/>

						<InputField
							type="email"
							className="col-lg-6 col-md-6 col-12"
							name="email"
							label="Email"
							required={true}
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && formik.errors.email || ''}
						/>

						<PhoneNumberField
							value={formik.values.phone_number}
							className="col-lg-6 col-md-6 col-12"
							label="Phone number"
							required={true}
							country="in"
							onBlur={formik.handleBlur}
							onChange={(phone) => {
								formik.setFieldValue('phone_number', phone || '')
							}}
							disabled={false}
							error={formik.touched.phone_number && formik.errors.phone_number || ''}
						/>

						<div className="Register-password-field col-lg-6 col-md-6 col-12">
							<PasswordField
								name="password"
								label="Password"
								required={true}
								value={formik.values.password}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.password && formik.errors.password || ''}
							/>
						</div>

						<div className="Register-password-field col-lg-6 col-md-6 col-12">
							<PasswordField
								name="confirm_password"
								label="Confirm password"
								required={true}
								value={formik.values.confirm_password}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.confirm_password && formik.errors.confirm_password || ''}
							/>
						</div>

						<Button name="Sign Up" type="submit" className={`btn btn-success ${loading ? 'btn-loading' : ''} `}
							disabled={loading}
							label="Register"
						/>
					</form>

					<div className="login-link-button">
						<p>Already have an account?&nbsp;<Link className="btn btn-link" to={routes.login}>Login</Link></p>
					</div>
				</div>
				{error.message && <Alert {...error} />}
			</div>

			<div className="col-lg-6 col-md-12">
				<div className="hand-moblie-col">
					<img src={HandMobile} alt="Hand Mobile" />
				</div>
			</div>
		</>
	);
};

export default Register;
