import React, { ChangeEvent, ReactElement } from 'react';
import { Search } from 'assets/icons';

interface SearchFieldProps {
	label?: string;
	name?: string;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	type?: 'text'
	disabled?: boolean;
	loading?: boolean;
	required?: boolean;
	className?: string;
	error?: string;
}

const SearchField: React.FC<SearchFieldProps> = (props: SearchFieldProps): ReactElement => {
	return (
		<div className={'form-group mb-3'}>
			{props.label && <label className="form-label">{props.label} {props.required && <span className="text-danger">*</span>}</label>}
			<div className="input-group">
				<button type="submit" disabled={props.disabled} className={`input-group-text ${props.loading ? 'btn-loading' : ''}`}>
					<img src={Search} alt="Search" width={15} height={15} />
				</button>
				<input
					type={props.type}
					autoComplete="off"
					name={props.name}
					onChange={props.onChange}
					value={props.value}
					placeholder="Keyword"
					className="form-control"
					{...props}
				/>
			</div>

			{props.error && <span className="invalid-feedback text-danger d-block mt-1">{props.error}</span>}
		</div>
	);
};

export default SearchField;
