import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';

import { Logo } from 'assets/images';
import { Button, InputField, PhoneNumberField, SelectField, TextArea } from 'views/stories';
import { LeftArrow, RightsArrowWhite, TimeIcon } from '../dashboard/assets/icons';
import { customerService } from 'services/customer.service';
import { _Object, doDateFormat, formatTime, getSixMonthsIncludingCurrent, routes } from 'utils/common-functions';
import Alert from 'views/components/alerts';
import { SEOHeader } from 'views/components';

const initialValue = {
	start_time: '',
	end_time: '',
	booking_date: doDateFormat(new Date()),
	internal_note: '',
	customer_name: '',
	customer_note: '',
	customer_email: '',
	customer_phone1: '',
	customer_phone2: '',
	service_id: ''
};

const Users = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const monthsList = getSixMonthsIncludingCurrent();

	const [currentDate, setCurrentDate] = useState<Moment>(moment());
	const [selectedMonth, setSelectedMonth] = useState<string>(monthsList[0]);
	const [services, setServices] = useState<_Object>([]);
	const [error, setError] = useState<{
		message?: string
		error?: boolean,
	}>({})

	const getWeekDays = (date: Moment): Moment[] => {
		const startOfWeek = moment(date).startOf('isoWeek');
		return Array.from({ length: 7 }).map((_, i) => startOfWeek.clone().add(i, 'days'));
	};

	const weeks = getWeekDays(currentDate);
	const currentWeekStart = moment().startOf('isoWeek');

	const [selectedService, setSelectedService] = useState<_Object>({});
	const [businessDetails, setBusinessDetails] = useState<_Object>({});
	const [bookingSlots, setBookingSlots] = useState([]);
	// const [initialData, setInitialData] = useState<{ [key: string]: string }>(initialValue);

	const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		setLoading({ booking: true })
		customerService.getBusinessDetails(id || '').then((data: any) => {
			setBusinessDetails(data);
			customerService.getServices(id || '').then((data: any) => {
				setServices(data);
				setLoading({ booking: false })
			});
		});
	}, []);

	const formik = useFormik({
		initialValues: initialValue,
		enableReinitialize: true,
		validationSchema: Yup.object({
			customer_name: Yup.string().label('Name').required(),
			customer_email: Yup.string().label('Email').required(),
			customer_phone1: Yup.string()
				.matches(/^(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number format')
				.required('Phone number is required'),
			booking_date: Yup.string().label('Booking date').required(),
			service_id: Yup.string().label('Service').required(),
			start_time: Yup.string().label('Start time').required()
		}),
		onSubmit: (values) => {
			setLoading({ createBooking: true })
			setError({})
			customerService.createBooking(id || '', values).then((data: _Object) => {
				if (!data.error) {
					// toast.success(data?.message);
					navigate(`${routes.thankyou}`, { state: data.data })

					formik.resetForm()
				} else {
					// toast.error(data?.message);
					setError(data)
				}
				setLoading({ createBooking: false })
			})
		}
	});

	useEffect(() => {
		if (selectedService.id && formik.values.booking_date) {
			setLoading({ slots: true })
			customerService.getSlots(id || '', formik.values.booking_date, selectedService.id).then((data: any) => {
				if (data.error) {
					setBookingSlots([])
				} else {
					setBookingSlots(data);
				}
				setLoading({ slots: false })
			});
		}
	}, [selectedService, formik.values.booking_date]);

	const handlePreviousWeek = () => {
		const previousWeekStart = currentDate.clone().subtract(1, 'weeks').startOf('isoWeek');
		if (previousWeekStart.isSameOrAfter(currentWeekStart)) {
			const updatedDate = currentDate.clone().subtract(1, 'weeks');

			// Extract the year and month from the updated date
			const year = updatedDate.year();
			const month = updatedDate.format('MMMM'); // Full month name, e.g., 'August'
			setSelectedMonth(`${month} (${year})`);
			setCurrentDate(currentDate.clone().subtract(1, 'weeks'));
		}
	};

	const handleNextWeek = () => {
		const updatedDate = currentDate.clone().add(1, 'weeks');

		// Extract the year and month from the updated date
		const year = updatedDate.year();
		const month = updatedDate.format('MMMM'); // Full month name, e.g., 'August'
		setSelectedMonth(`${month} (${year})`);

		setCurrentDate(currentDate.clone().add(1, 'weeks'));
	};

	const handleMonthChange = (event: { [key: string]: string }) => {
		const selectedOption = event.value;
		const [monthName, year] = selectedOption.split(' ');
		const monthIndex = moment().month(monthName).month();
		const selectedDate = moment().year(parseInt(year, 10)).month(monthIndex).startOf('month');

		if (monthIndex === moment().month()) {
			const currentWeekOfMonth = currentDate.isoWeek() - currentDate.startOf('month').isoWeek() + 1;
			selectedDate.add(currentWeekOfMonth - 1, 'weeks');
		} else {
			const currentDay = moment().date();
			const weekStart = moment().startOf('isoWeek').date();
			const daysFromWeekStart = currentDay - weekStart;
			selectedDate.add(daysFromWeekStart, 'days');
		}

		setSelectedMonth(selectedOption);
		setCurrentDate(selectedDate);
	};

	return (
		<>
			<SEOHeader title="Book Appointment" />

			<div className="users-card-row">

				<div className="card w-100">
					<div className="row">

						{loading.booking ?
							<>
								<div className="col-8">
									<div className="users-card">
										<h5 className="card-title placeholder-glow">
											<span className="placeholder col-2 mb-3"></span>
										</h5>
										<p className="placeholder-glow">
											<span className="placeholder col-3"></span><br />
											<span className="placeholder placeholder-sm col-4"></span>
										</p>
										<br />
										<p className="placeholder-glow user-form-group">
											<div>
												<span className="placeholder user-placeholder-label"></span><br />
												<span className="placeholder user-placeholder-control"></span>
											</div>

											<div className="d-flex gap-2">
												<span className="placeholder arrow-button"></span><br />
												<span className="placeholder arrow-button"></span>

											</div>
										</p>

										<ul className="list-inline placeholder-glow user-time-list-placeholder">
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
										</ul>

										<p className="placeholder-glow user-form-meeting">
											<div>
												<span className="placeholder user-placeholder-label"></span><br />
												<span className="placeholder user-placeholder-control"></span>
											</div>

											<div className="d-flex gap-2">
												<span className="placeholder meeting-icon"></span>
												<span className="placeholder meeting-label"></span>
											</div>
										</p>

										<ul className="list-inline placeholder-glow user-time-list-group">
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
											<li className="list-inline-item placeholder"></li>
										</ul>
									</div>
								</div>

							</>
							:

							<div className="col-8">
								<div className="users-card">
									<div className="card-header ps-0 pt-0">

										<div className="brand-logo mb-0">
											<img src={Logo} alt="Logo" className="brand-logo-images" />
											<h4>{businessDetails.name || ''}</h4>
											<p className="mb-2">{businessDetails.bio || ''}</p>
										</div>

									</div>

									<div className="card-body ps-0 pb-0">
										<div className="row mb-3">
											<div className="col-6">
												<SelectField
													label="Month"
													name="monthsList"
													value={{ value: selectedMonth }}
													options={monthsList?.map((item: string) => ({ label: item, value: item }))}
													onChange={handleMonthChange}
												// required={true}
												/>
											</div>
											<div className="col-6 my-auto">
												<div className="user-arrow-button d-flex justify-content-end gap-2">
													<button type="button" className="btn btn-success" onClick={handlePreviousWeek} disabled={currentDate.startOf('isoWeek').isSame(currentWeekStart)}>
														<img src={LeftArrow} alt="Left Arrow" height={20}
															width={20} />
													</button>

													<button type="button" className="btn btn-success" onClick={handleNextWeek}>
														<img src={RightsArrowWhite} alt="Right Arrow" height={20}
															width={20} className="" />
													</button>
												</div>

											</div>
										</div>

										<div className="user-time-list mb-3">
											<ul className="list-inline">
												{weeks.map((day, index) => (
													<li key={index} className="list-inline-item">
														<input type="radio" className="btn-check" name="booking_date" id={day.format('ddd')}
															onChange={() => {
																formik.setFieldValue('booking_date', day.format('YYYY-MM-DD'))
															}} onBlur={formik.handleBlur}
															value={formik.values.booking_date}
															checked={formik.values.booking_date === day.format('YYYY-MM-DD')}
															disabled={day.isBefore(moment().startOf('day'))}
														/>
														<label className="btn btn-outline-secondary" htmlFor={day.format('ddd')}><p>{day.format('ddd')}</p><h6>{day.format('DD')}</h6></label>
													</li>
												))}
											</ul>
										</div>

										<div className="d-flex justify-content-between align-items-center min-meeting-content">
											<div className="lost-angeles-dropdown">
												<SelectField
													label="Services"
													name="service_id"
													value={{ label: selectedService.title }}
													options={services?.map((item: { [key: string]: string }) => ({ label: item.title, value: item }))}
													// onBlur={formik.handleBlur}
													onChange={(e) => {
														formik.setFieldValue('service_id', e.value?.id);
														setSelectedService(e.value)
													}}
													error={!formik.values.service_id && formik.errors.service_id || ''}
													required={true}
												/>
											</div>

											{selectedService?.duration && <p>
												<img src={TimeIcon} alt="Meeting" height={20} width={20} />&nbsp;{selectedService.duration || '30'} Min Meeting</p>}
										</div>

										<div className="slod-button-group">
											{loading.booking || loading?.slots ? (
												<ul className="list-inline placeholder-glow user-time-list-group">
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
													<li className="list-inline-item placeholder m-1"></li>
												</ul>
											) : (
												<ul className="list-inline mb-0">
													{bookingSlots?.map((item: { [key: string]: string }, i: number) => (
														<li className="list-inline-item" key={i}>
															<Button
																type="button"
																className={`btn btn-outline-success mb-2 ${(
																	formik.values.start_time === `${item.startTime}:00` ||
																	formik.values.start_time === item.startTime ||
																	formik.values.end_time === `${item.endTime}:00` ||
																	formik.values.end_time === item.endTime
																) ? 'active' : ''}`}
																onClick={() => {
																	formik.setFieldValue('start_time', item.startTime);
																	formik.setFieldValue('end_time', item.endTime);
																}}
															>
																{formatTime(item.startTime)} <span>to</span> {formatTime(item.endTime)}
															</Button>								</li>
													))}
												</ul>
											)}
										</div>
									</div>
								</div>
							</div>}

						{formik.values.start_time &&
							<div className="col-4">
								<div className="users-inner-form">
									<form onSubmit={formik.handleSubmit}>
										<div className="profile-form card-body pb-0 pe-0">
											<h4>Contact information</h4>
											<div className="row">
												<div className="">
													<InputField
														label="Name"
														name="customer_name"
														required={true}
														placeholder="Enter here"
														value={formik.values.customer_name}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														error={formik.touched.customer_name && formik.errors.customer_name || ''}
													/>
												</div>

												<div className="">
													<InputField
														label="Email"
														name="customer_email"
														placeholder="Enter here"
														value={formik.values.customer_email}
														onChange={formik.handleChange}
														required={true}
														onBlur={formik.handleBlur}
														error={formik.touched.customer_email && formik.errors.customer_email || ''}
													/>
												</div>

												<div className="">
													<PhoneNumberField
														className="custom-phone-number-field"
														label="Phone number"
														required={true}
														country="in"
														onBlur={formik.handleBlur}
														value={formik.values.customer_phone1}
														onChange={(phone) => {
															formik.setFieldValue('customer_phone1', phone);
														}}
														disabled={false}
														placeholder="Enter phone number..."
														error={formik.touched.customer_phone1 && formik.errors.customer_phone1 || ''}
													/>
												</div>
												<div className="">
													<TextArea
														label="Note"
														name="customer_note"
														value={formik.values.customer_note}
														rows={4}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														disabled={false}
														placeholder="Enter here"
													// required={true}
													/>
												</div>
											</div>
										</div>
										<div className="card-footer">
											<Button
												label="Submit"
												type="submit"
												className={`btn btn-success ${loading.createBooking ? 'btn-loading' : ''} `}
												disabled={loading.createBooking || false}
											/>
											{error.message && <Alert {...error} />}
										</div>

									</form>
								</div>
							</div>}
					</div>

				</div >
			</div >
			<div>

			</div>

		</>
	)
}
export default Users