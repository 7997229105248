import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services';
import store from 'store';
import { _Object } from 'utils/common-functions';

const initialState = {
	cartData1: [],
	isUserLoggedIn: store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') ? true : false,
	loggedInUser: {},
	loading: false
}

export const setLoggedInUser = createAsyncThunk('setLoggedInUser', async () => {
	return await authService.getMe()
})

const sessionSlice = createSlice({
	name: 'session',
	initialState: initialState,
	reducers: {
		setTokenSession: (state, action) => {
			store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9', action.payload)
			state.isUserLoggedIn = true
		},

		destroyAuthSession: (state) => {
			store.clearAll()
			state.isUserLoggedIn = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(setLoggedInUser.pending, (state) => {
				state.loggedInUser = {}
				state.loading = true
			})
			.addCase(setLoggedInUser.fulfilled.toString(), (state, action: _Object) => {
				state.loggedInUser = action.payload || {}
				state.loading = false
			})
			.addCase(setLoggedInUser.rejected, (state) => {
				state.loggedInUser = {}
				state.loading = false
			});
	}
})

export const { setTokenSession, destroyAuthSession } = sessionSlice.actions

export default sessionSlice.reducer