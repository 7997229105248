import { generateQueryParams } from 'utils/common-functions';

import CommonService from './common.service';

class SyncTimeService extends CommonService {
	async getServices(params?: { [key: string]: boolean | string }) {
		return await this.get(`services/?${generateQueryParams(params)}`)
	}
	async getServiceDetails(id: string) {
		return await this.get(`services/${id}`)
	}
	async createService(parmas: { [key: string]: boolean | string }) {
		return await this.post('services', parmas)
	}
	async updateService(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`services/${id}`, params)
	}
	async deleteService(id: string) {
		return await this.delete(`services/${id}`)
	}
}
export const syncTimeService = new SyncTimeService();
