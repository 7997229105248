import React, { ChangeEvent } from 'react';

interface InputFieldProps {
	type?: 'text' | 'email' | 'color' | 'date' | 'file' | 'number';
	label?: string;
	name?: string;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	placeholder?: string;
	required?: boolean;
	error?: string;
	[key: string]: any;
}

const InputField: React.FC<InputFieldProps> = ({
	type = 'text',
	label,
	name,
	value = '',
	onChange,
	className = '',
	placeholder = 'Enter here',
	required = false,
	error,
	...props
}: InputFieldProps) => {
	return (
		<div className={`form-group ${className} ${error ? 'invalid' : ''}`}>
			{label && (
				<label className="form-label">
					{label}
					{required && <span className="text-danger">*</span>}
				</label>
			)}
			<input
				type={type}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				className="form-control"
				autoComplete="off"
				{...props}
			/>
			{error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
		</div>
	);
};

export default InputField;
