import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Filters, SEOHeader } from 'views/components';
import { Pagination, SearchField } from 'views/stories';
import { bookingService } from 'services';
import { _Object, calandarDateFormat, capitalize, capitalizeFirstLetter, formatTime } from 'utils/common-functions';
import { DeleteButton, EditButton, EyeButton, Plus, ListIcon, CloseWhiteButton } from '../assets/icons';
import AddEditBooking from './add-edit-booking';
import BookingCalendar from 'views/components/calendar';
import BookingDetails from 'views/components/booking-details';
import { Booking } from 'views/components/sidebar/assets/icons';
import DeleteBooking from './delete-booking';

const Bookings = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedBooking, setSelectedBooking] = useState<any>('');
	const [view, setView] = useState<any>('');
	const [bookings, setBookings] = useState<_Object>({});
	const [selectedTab, setSelectedTab] = useState('bar');
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		size: 10,
		sort: '-created_at',
		q: '',
		filter: {
			booking_date: ''
		}
	});

	useEffect(() => { getBookings() }, [filterData])
	const getBookings = () => {
		setLoading(true)
		bookingService.getBookings(filterData).then((data: _Object) => {
			setBookings(data)
			setLoading(false)
		})
	}
	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '') {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});

	const handleFilterChange = (newFilters: { booking_date: string, status: string }) => {
		setFilterData((prev) => ({
			...prev.filter,
			filter: { booking_date: newFilters.booking_date, status: newFilters.status }
			// page: 1
		}));
	};
	const removeFilter = (filterType: 'booking_date' | 'status', filterItem: string) => {
		setFilterData((prev) => {
			let updatedFilter = prev.filter[filterType];

			if (filterType === 'status') {
				updatedFilter = updatedFilter
					.split(',')
					.filter((item: string) => item !== filterItem)
					.join(',');
			} else if (filterType === 'booking_date') {
				updatedFilter = '';
			}

			return {
				...prev,
				filter: {
					...prev.filter,
					[filterType]: updatedFilter
				}
			};
		});
	};
	return (
		<>
			<SEOHeader title="Bookings New" />
			<div className="booking-section">
				<div className="space-between align-middles pb-10">
					<h5 className="mb-0">Bookings</h5>
					<button
						type="button"
						className="btn btn-success"
						data-bs-toggle="modal"
						data-bs-target="#NewAppontment"
						onClick={() => setSelectedBooking('')}
					>
						<img src={Plus} alt="Add" width={15} height={15} />&nbsp; Add Booking
					</button>

				</div>

				<div className="card">
					<div className="card-header space-between align-middles">
						<div className="d-flex gap-3">
							<form onSubmit={formik.handleSubmit}>
								<div className="input-group">
									<SearchField
										name="q"
										value={formik.values.q}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue('q', e.target.value);
										}}
									/>
								</div>
							</form>

							<Filters onFilterChange={handleFilterChange} />

							{filterData?.filter?.status?.split(',')?.map((item: string) => (
								item && (
									<>
										<div className="my-auto">
											<button type="button" className="btn btn-success text-capitalize" onClick={() => removeFilter('status', item)} >
												{item}&nbsp; &nbsp;
												<img src={CloseWhiteButton} alt="Remove Break" width={10} height={10} />
											</button>
										</div>
									</>
								)
							))}
							{filterData?.filter?.booking_date && (
								<div className="my-auto">
									<button
										type="button"
										className="btn btn-success"
										onClick={() => removeFilter('booking_date', filterData.filter.booking_date)}
									>
										{calandarDateFormat(filterData.filter.booking_date)}&nbsp; &nbsp;
										<img src={CloseWhiteButton} alt="Remove Break" width={10} height={10} />

									</button>
								</div>
							)}
						</div>

						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<button
									className="btn p-0 border-0 booking-tab"
									onClick={() => setSelectedTab('bar')}>
									<img src={ListIcon} alt="List Icon" width={15} height={15} className={selectedTab === 'bar' ? 'active' : ''} />
								</button>
							</li>

							<li className="list-inline-item">
								<button
									className="btn p-0 border-0 booking-tab"
									onClick={() => setSelectedTab('calendar')}>
									<img src={Booking} alt="Calendar" width={15} height={15} className={selectedTab === 'calendar' ? 'active' : ''} />
								</button>
							</li>
						</ul>
					</div>

					{selectedTab === 'bar' ?
						<>
							<div className="card-body table-responsive">
								<table className="table">
									<thead>
										<tr>
											{/* <th>#</th> */}
											<th>Booking #</th>
											<th>Customer</th>
											<th>Booking Date</th>
											<th>Service</th>
											<th>Status</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>

									<tbody>
										{loading ?
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<div className="spinner-border text-secondary" role="status">
														</div>
													</div>
												</td>
											</tr> :
											bookings.items?.map((item: any, i: number) => {
												const today = new Date();
												const bookingDate = new Date(item.booking_date);
												return (
													<>
														<tr key={i}>
															<td>{item?.booking_number}</td>
															<td>
																<p className="mb-1"><b>Name:</b> {capitalize(item.customer_name) || '-'}</p>
																<a href={`mailto:${item.customer_email}`}>
																	<p className="mb-1"><b>Email:</b> {item.customer_email || '-'}</p>
																</a>
																<a href={`tel:${item.customer_phone1}`}>
																	<p className="mb-1"><b>Phone no:</b> {item.customer_phone1 || '-'}</p>
																</a>
																{item.customer_phone2 && <p><b>Phone no2:</b> {item.customer_phone2 || '-'}</p>}
															</td>
															<td>
																<p className="mb-1"><b>Date:</b> {calandarDateFormat(item.booking_date)}</p>
																<p className="mb-1"><b>Time:</b> {item.start_time && item.end_time ? `${formatTime(item.start_time)} - ${formatTime(item.end_time)}` : '-'}</p>
															</td>
															<td>
																<p className="mb-1">{capitalizeFirstLetter(item?.service.title) || '-'}</p>
															</td>
															<td>
																<p className="mb-1">{capitalizeFirstLetter(item?.status) || '-'}</p>
															</td>
															<td>
																<ul className="list-inline">
																	{item.booking_date && bookingDate <= today && (<li className="list-inline-item">
																		<a href="#" className="eye-edit" type="button" data-bs-toggle="modal" data-bs-target="#NewAppontment" onClick={() => setSelectedBooking(item?.id)}>
																			<img src={EditButton} alt="Edit" width={22} height={20} />
																		</a>
																	</li>)}
																	<li className="list-inline-item">
																		<a href="#" className="eye-button" type="button" data-bs-toggle="modal" data-bs-target="#ViewAppointment" onClick={() => setView(item)}>
																			<img src={EyeButton} alt="Eye" width={22} height={20} />
																		</a>
																	</li>
																	<li className="list-inline-item">
																		<button type="button" onClick={() => setSelectedBooking(item?.id)} data-bs-toggle="modal" data-bs-target="#DeleteBooking" className="btn p-0 border-0 eye-delete">
																			<img src={DeleteButton} alt="Delete" width={18} height={20} />
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													</>
												)
											})}
										{bookings.items?.length === 0 &&
											<tr>
												<td colSpan={12} className="text-center">
													No record found
												</td>
											</tr>
										}
									</tbody>
								</table>
								<BookingDetails props={view} id={'ViewAppointment'} />
							</div>

							{bookings?.pagination?.total_pages > 1 &&
								<div className="card-footer space-between align-middles">
									<div className="pagination-wrap">
										<Pagination
											total_pages={bookings?.pagination?.total_pages}
											current_page={bookings?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
									<div className="space-between align-middles gap-3">
										<span>Showing {bookings?.items?.length} to {bookings?.pagination?.total_items}</span>
										<div className="data-entry">
											<select className="form-select"
												value={filterData.size}
												onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
													setFilterData((prev) => ({
														...prev,
														size: Number(event.target.value),
														page: 1
													}))
												}>
												<option value="10">Show 10</option>
												<option value="20">20</option>
												<option value="30">30</option>
												<option value="40">40</option>
											</select>
										</div>
									</div>
								</div>
							}
						</>
						:
						<p>
							<BookingCalendar bookings={bookings} setBookings={setBookings} />
						</p>
					}
				</div>
			</div>
			<AddEditBooking id={selectedBooking} getBookings={getBookings} />
			<DeleteBooking getBookings={getBookings} selectedBooking={selectedBooking} />
		</>
	);
};

export default Bookings;

